import { Upload, Button, message, Avatar } from "antd";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";

const ImageUpload = ({
  maxCount,
  allowedTypes,
  onChange,
  fileList,
  imageUrl,
}) => {
  const handleChange = ({ fileList }) => {
    onChange(fileList);
  };

  const beforeUpload = (file) => {
    const isImage = allowedTypes.includes(file.type);
    if (!isImage) {
      message.error("画像ファイルのみアップロードできます");
    }
    return false;
  };

  return (
    <div className="avatar-container">
      {imageUrl && (
        <Avatar size={100} src={imageUrl} style={{ marginBottom: 16 }} />
      )}
      <Upload
        name="avatar"
        listType="picture"
        maxCount={maxCount}
        fileList={fileList}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        <Button icon={<UploadOutlined />}>プロフィール画像を選択</Button>
      </Upload>
    </div>
  );
};

export default ImageUpload;
