import AmazonProductItem from './AmazonProductItem'
import './index.scss'
import { useDispatch, useSelector } from 'react-redux'
import { List, Button } from 'antd'
import { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { handlerLoadMoreAPI } from '../../apis/AmazonSearch'
import classNames from 'classnames'
import { useSpring, animated } from '@react-spring/web'
import ScrollWithThumb from '../../components/CustomScrollbars';

const AmazonProductMenu = ({ onClose, sponsorFilter }) => {
  const { productList } = useSelector((state: any) => state.amazonProduct);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreSearch, setHasMoreSearch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [moreProductList, setMoreProductList] = useState<any[]>([]);
  const [dots, setDots] = useState<string>('');
  const timerRef = useRef<number | null>(null);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [iconActive, setIconActive] = useState(false);
  const [eyeAngle, setEyeAngle] = useState(0); // 初期回転角度 0°
  const eyeBallRef = useRef<HTMLDivElement>(null);
  const scrollIntervalRef = useRef<number | null>(null);
  const [eyeScale, setEyeScale] = useState(0.4); // 初期スケール比率1.3
  // アニメーション設定を追加
  const [props, api] = useSpring(() => ({
    scale: 0.4,
    config: {
      tension: 100,  // バネの張力
      friction: 20   // 摩擦力
    }
  }))


  const startDotsTimer = () => {
    // タイマーがまだ起動していない場合は起動する
    if (timerRef.current === null) {
      timerRef.current = window.setInterval(() => {
        setDots(prev => (prev.length < 3 ? prev + '.' : '.'));
      }, 500);
    }
  };

  const stopDotsTimer = () => {
    if (timerRef.current !== null) {
      clearInterval(timerRef.current);
      timerRef.current = null;
      setDots(''); // ドットをクリア
    }
  };

  // ビジネスロジックに基づいて次のページデータを読み込む処理関数
  const handleLoadMore = async () => {
    if (!hasMoreSearch) return;
    console.log('handleLoadMore');
    if (loading) return; // 重複読み込みを防ぐ
    setLoading(true);
    // ドットタイマーを開始
    startDotsTimer();
    try {
      const result = await handlerLoadMoreAPI(sponsorFilter);
      console.log('検索APIの結果:', result);
      if (result.message === 'searchSuccessfully') {
        setMoreProductList(prev => [...prev, ...result.ProductList]);
        console.log('result.ProductList:', result.ProductList);
      } else {
        setHasMoreSearch(false);
      }
    } catch (error) {
      console.error('検索APIエラー:', error);
    }
    stopDotsTimer();
    setLoading(false);
    // 例：dispatch を使用して次のページ読み込みアクションをトリガー
    // dispatch(fetchMoreProducts());
  };
  // トップにスクロールする処理関数を追加
  const scrollToTop = () => {
    setIconActive(true);
    setTimeout(() => {
      setIconActive(false);
    }, 1200);
    const searchIcon = document.querySelector('.search-icon');
    searchIcon?.scrollIntoView({ behavior: 'smooth' });
  };

  // コンポーネント内部にrefを定義して現在のスクロール速度を保存
  const speedRef = useRef<number>(20); // 初期速度 20px/サイクル

  let accelerationPerInterval = 40; // 100msごとに20pxずつ増加

  // 自動スクロール関数：現在の速度でスクロールし、加速度を増加
  const startAutoScroll = () => {
    if (scrollIntervalRef.current) return;
    // スケールアニメーションを開始
    api.start({
      // scale: 2.4,
      scale: 0.6,
      config: {
        duration: 1000 // 1秒で拡大完了
      }
    })
    // 開始時に速度をリセット
    speedRef.current = accelerationPerInterval;
    scrollIntervalRef.current = window.setInterval(() => {
      const categoryEl = document.querySelector('.category');
      if (categoryEl) {
        // behavior: 'auto'で即座にスクロール
        categoryEl.scrollBy({ top: speedRef.current, behavior: 'smooth' });
      }

      if (speedRef.current < 4000) {
        // 速度増加：各周期でaccelerationPerIntervalを加算
        speedRef.current = speedRef.current + accelerationPerInterval;
      }
      else {
        // 速度が1000px/周期に達したら一定に保つ
        speedRef.current = 4000;
      }
      if (accelerationPerInterval < 200) {
        accelerationPerInterval += 40;
      } else {
        accelerationPerInterval = 200;
      }
      console.log('speedRef.current:', speedRef.current);
    }, 100);
  };


  const stopAutoScroll = () => {
    setEyeScale(0.4); // マウスを離した時、0.5倍に戻す
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }
    // 初期サイズに戻す
    api.start({
      scale: 0.4,
      config: {
        duration: 300 // 0.3秒で戻す
      }
    })
  };

  // loadMore領域を観察するためのrefを作成
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  // Intersection Observerを使用して自動的にデータを読み込む
  useEffect(() => {
    if (!loadMoreRef.current || !hasMore) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        handleLoadMore();
      }
    });
    observer.observe(loadMoreRef.current);
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [handleLoadMore, hasMore]);

  useEffect(() => {
    const handleScroll = () => {
      const categoryElement = document.querySelector('.category');
      // スクロール位置が1000pxを超えているかどうかで表示状態を切り替える
      setShowBackToTop(categoryElement.scrollTop > 1000 ? true : false);
    };

    // スクロールイベントリスナーを追加
    const categoryElement = document.querySelector('.category');
    categoryElement?.addEventListener('scroll', handleScroll);

    // クリーンアップ関数：コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      categoryElement?.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // マウスの移動を監視し、eye-ballの中心に基づいて回転角度を計算
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent | TouchEvent) => {
      if (!eyeBallRef.current) return;
      const rect = eyeBallRef.current.getBoundingClientRect();
      const cx = rect.left + rect.width / 2;
      const cy = rect.top + rect.height / 2;
      // マウスまたはタッチポイントの座標を取得
      let clientX, clientY;
      if (e instanceof MouseEvent) {
        clientX = e.clientX;
        clientY = e.clientY;
      } else {
        // TouchEvent
        const touch = (e as TouchEvent).touches[0];
        clientX = touch.clientX;
        clientY = touch.clientY;
      }

      const deltaX = clientX - cx;
      const deltaY = cy - clientY;
      // 角度を計算（θ = arctan(|deltaY|/|deltaX|)）、度数に変換
      const theta = deltaX !== 0 ? Math.atan(Math.abs(deltaY) / Math.abs(deltaX)) * (180 / Math.PI) : 90;
      let angle = 0;
      if (deltaX < 0) {
        // マウスが左側にある場合
        if (deltaY >= 0) {
          // 上方または水平、回転 = -(90 - θ)
          angle = -(90 - theta);
        } else {
          // 下方、回転 = -(90 + θ)
          angle = -(90 + theta);
        }
      } else if (deltaX > 0) {
        // マウスが右側にある場合
        if (deltaY >= 0) {
          // 上方または水平、回転 = 90 - θ
          angle = 90 - theta;
        } else {
          // 下方、回転 = 90 + θ
          angle = 90 + theta;
        }
      }
      setEyeAngle(angle);
    };

    // タッチイベントリスナーを追加
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleMouseMove);
    };
  }, []);
  // 既存データと新規データを結合してレンダリングに使用
  const allProducts = [...productList, ...moreProductList];

  // 画面幅に基づいて現在の列数を決定（ここでは簡単にwindow.innerWidthを使用、実際の使用時はレスポンシブな方法を検討する）
  const columns = window.innerWidth >= 1400 ? 4 : window.innerWidth >= 701 ? 2 : 1;

  // allProductsデータを前処理し、各行を完全にするためにダミー項目を追加
  const processedItems = useMemo(() => {
    let items = [...allProducts];
    const remainder = items.length % columns;
    if (remainder !== 0) {
      const dummyCount = columns - remainder;
      for (let i = 0; i < dummyCount; i++) {
        items.push({ dummy: true });  // ダミーを空の位置取り項目としてマーク
      }
    }
    return items;
  }, [allProducts, columns]);
  const [flag, setFlag] = useState(false);

  // リストデータが更新された時、.load-moreの背景色を最も近い.ant-list-itemのbackgroundColorに動的に設定
  useEffect(() => {
    if (loadMoreRef.current) {
      const listItems = document.querySelectorAll('.ant-list-item');
      if (listItems.length > 0) {
        const lastItem = listItems[listItems.length - 1];
        const computedStyle = window.getComputedStyle(lastItem);
        loadMoreRef.current.style.backgroundColor = computedStyle.backgroundColor;
      }
    }
  }, [processedItems, loading]); // データが変更された時にトリガー

  return (
    <ScrollWithThumb thumbImageUrl={flag ? "/uploads/default/lightSubmarine.png" : "/uploads/default/submarine.png"} onFlagChange={flag => { setFlag(flag) }}>
      <div className="category">
        <div className={classNames("search-icon", { active: iconActive })}
          onClick={() => {
            // 検索アイコンをクリックした時、onCloseコールバックを実行して親コンポーネントの表示状態を切り替える
            onClose();
          }}>
          <img src='/uploads/default/searchIcon.jpeg' alt="検索アイコン" />
        </div>
        <dl className="cate-list">
          <dt className="cate-title">Amazon</dt>
          <div className="cate-banner"></div>
          <List
            dataSource={processedItems}
            renderItem={(item: any, index: number) => {
              // 現在の項目の行番号を計算（1から始まる）
              const row = Math.floor(index / columns) + 1;
              // 各行の色を暗くするステップ（ここでは各行のRGBをそれぞれ5ずつ減少）
              const offset = (row - 1) * 5;
              // 新しい色を計算、各チャンネルの値を調整
              const r = Math.max(67 - offset, 0);
              const g = Math.max(182 - offset, 0);
              const b = Math.max(204 - offset, 0);
              const bgColor = `rgb(${r}, ${g}, ${b})`;

              // ダミーの位置取り項目の場合、空のコンテナを返すが、背景色は維持
              if (item.dummy) {
                return (
                  <List.Item key={`dummy-${index}`} style={{ backgroundColor: bgColor }}>
                    <div style={{ width: '100%', height: '100%' }} />
                  </List.Item>
                );
              }
              return (
                <List.Item key={index} style={{ backgroundColor: `rgb(${r}, ${g}, ${b})` }}>
                  <AmazonProductItem id={index} {...item} />
                </List.Item>
              );
            }}
            loadMore={
              <div ref={loadMoreRef} className="load-more">
                <span className="load-more-content">
                  {loading ? (
                    <>
                      読み込み中
                      <span className="dots">{dots}</span>
                    </>
                  ) : (
                    <span>最後まで読み込みました</span>
                  )}
                </span>
              </div>
            }
          />
          <div
            className={classNames("eye-ball")}
            ref={eyeBallRef}
            onMouseDown={startAutoScroll}
            onMouseUp={stopAutoScroll}
            onMouseLeave={stopAutoScroll}
            onTouchStart={startAutoScroll}
            onTouchEnd={stopAutoScroll}
            onTouchCancel={stopAutoScroll}
          >
            <animated.img
              className='eye'
              src='/uploads/default/eye.png'
              style={{
                transform: props.scale.to(s => `rotate(${eyeAngle}deg) scale(${s})`)
              }}
            />
            <img
              className='ball'
              src='/uploads/default/eyeOfHorus3.png'  // 画像調整元はeyeball.png
            />
          </div>
          {showBackToTop && (
            <div
              className={classNames("back-to-top", { active: iconActive })}
              onClick={scrollToTop}
            >
              <img
                src='/uploads/default/searchIconReverse.jpg'
                alt="トップへ戻る"
              />
            </div>
          )}
        </dl>
      </div>
    </ScrollWithThumb>
  )
}

export default AmazonProductMenu