import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withPageReload = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      let isPageReloaded: string | null = null;
      if (sessionStorage.getItem('isPageReloaded') !== null) {
        isPageReloaded = sessionStorage.getItem('isPageReloaded');
      }
      const currentPath = window.location.href;
      console.log("currentPath", currentPath);
      // 判定のパス名リスト
      const pathsToCheck = ['paymentStatusCheck', 'orderCompletion'];
      // リクエストされたルートがリストのいずれかに含まれている場合、直接 return する
      if (pathsToCheck.some(path => currentPath.includes(path))) {
        return;
      }
      if (isPageReloaded) {
        // 页面被刷新
        navigate('/');
      } else {
        // 设置标志，表示页面已加载
        sessionStorage.setItem('isPageReloaded', 'true');
      }

      const hash = window.location.pathname;
      console.log(hash);
      const shopNameMatch = hash.match(/\/([^/#]+)/);
      const shopName = shopNameMatch ? decodeURIComponent(shopNameMatch[1]) : '';
      console.log(shopName);

      if (shopName) {
        localStorage.setItem("shopName", shopName);
      }

      // 清理函数，在组件卸载时移除标志
      return () => {
        sessionStorage.removeItem('isPageReloaded');
      };
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };
};

export default withPageReload;