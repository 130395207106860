import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  Breadcrumb,
  Form,
  Button,
  Radio,
  DatePicker,
  Select,
  Popconfirm,
  Input,
  Modal,
  message,
  Pagination,
  Collapse,
  Menu,
} from 'antd';
// 引入汉化包 时间选择器显示中文
import locale from 'antd/es/date-picker/locale/zh_CN';
import './index.scss';
// 导入资源
import { Table, Tag, Space } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
// import { useChannel } from '@/hooks/useChannel'
import { useEffect, useState, useMemo } from 'react';
// import { delArticleAPI, getArticleListAPI } from '@/apis/article'
import {
  getFoodsAPI,
  updateFoodTagAPI,
  updateFoodAPI,
  updateStatusAPI,
  addFoodAPI,
  deleteFoodsAPI,
  uploadFoodPictureAPI,
  updateFoodPictureAPI,
  searchFoodsAPI,
} from '../../apis/food';
import {
  getTagsAPI,
  addTagAPI,
  updateTagAPI,
  deleteTagAPI,
} from '../../apis/tag';
import {
  getFlavorsAPI,
  updateFoodFlavorsAPI,
  deleteFlavorAPI,
  addFlavorsAPI,
} from '../../apis/flavors';
import { getAllComboDetailAPI } from '../../apis/combo';
import ResizableTitle from '../../components/ResizableTitle';
import TagDropdown from '../../components/TagDropdown';
import StatusDropdown from '../../components/StatusDropdown';
import TaglistDropdown from '../../components/taglistDropdown';
import FoodImageUpload from '../FoodImageUpload';
import ManagementNavbar from './Navbar';
import { useSelector } from 'react-redux';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const Shop = () => {
  const navigate = useNavigate();
  // 获取文章列表
  const [foodList, setFoodList] = useState([]);
  const [tags, setTags] = useState([]);
  const [count, setCount] = useState(0);
  const [searchForm] = Form.useForm();
  const [foodForm] = Form.useForm();
  const [foodAddForm] = Form.useForm();
  const [isAddFoodModalOpen, setIsAddFoodModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isCategoryEditModalOpen, setIsCategoryEditModalOpen] = useState(false);
  const [isFoodModalOpen, setIsFoodModalOpen] = useState(false);
  const [isFoodModalVisible, setIsFoodModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState('type');
  const [categoryName, setCategoryName] = useState('');
  const [foodName, setFoodName] = useState('');
  const [currentFood, setCurrentFood] = useState(null);
  const [selectFoodCategory, setSelectFoodCategory] = useState(null);
  const [selectFoodStatus, setSelectFoodStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [fileList, setFileList] = useState([]);
  const [selectedKey, setSelectedKey] = useState('list');
  const { shopId } = useSelector(
    (state: any) => state.user.shop
  );
  const handleImageChange = (newFileList) => {
    if (newFileList.length === 0) {
      setFileList([]);
      foodAddForm.setFieldsValue({ picture: '' });
      foodForm.setFieldsValue({ picture: '' });
      return;
    }
    // 使用 JSON.stringify 进行深度比较
    if (JSON.stringify(newFileList) !== JSON.stringify(fileList)) {
      console.log('Selected files:', newFileList);
      setFileList(newFileList);
      foodAddForm.setFieldsValue({ picture: newFileList[0].name });
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const getList = async () => {
    // 获取 foods 数据
    const res: any = await getFoodsAPI();
    setFoodList(res);

    // 计算每一个 foods 下的长度
    const totalCount = res.reduce(
      (acc, item) => acc + (item.foods ? item.foods.length : 0),
      0
    );
    setCount(totalCount);

    // 获取 tags 数据
    const tagsRes: any = await getTagsAPI();
    setTags(tagsRes); // 假设你有一个 setTags 函数来设置 tags 数据
  };

  const handleFoodCategorySelect = (category) => {
    setSelectFoodCategory(category);
  };
  const handleFoodStatusSelect = (status) => {
    setSelectFoodStatus(status);
  };

  const showAddFoodModal = () => {
    setIsAddFoodModalOpen(true);
  };

  const showFoodModal = (data) => {
    console.log('newdata', data);
    setCurrentFood({
      ...data,
      currentPage: 1,
      pageSize: 5,
    });
    const imageUrl = data.picture;
    if (imageUrl) {
      const formattedFileList = [
        {
          uid: '-1',
          name: imageUrl.substring(imageUrl.indexOf('-') + 1),
          status: 'done',
          url: imageUrl,
        },
      ];
      setFileList(formattedFileList);
    }
    setIsFoodModalVisible(true);
  };

  const handleFoodTagOpen = () => {
    setCategoryName('');
    setIsCategoryModalOpen(true);
  };

  const onOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleCategoryOk = async () => {
    // 处理添加料理カテゴリー的逻辑
    console.log('添加料理カテゴリー:', categoryName);
    if (categoryName === '' || categoryName === null) {
      message.error('カテゴリー名を入力してください');
      return;
    }
    if (categoryName === selectedCategory) {
      message.error('更新する必要がありません');
      return;
    }
    const selectedTag = tags.find((tag) => tag.name === selectedCategory)?.tag;
    console.log('selectedTag', selectedTag);

    if (isCategoryModalOpen) {
      try {
        const result: any = await addTagAPI(categoryName);
        if (result.success === true) {
          message.success('カテゴリー追加に成功しました');
          handleCategoryCancel();
          const tagsRes: any = await getTagsAPI();
          setTags(tagsRes);
          setSelectedCategory(categoryName);
          searchForm.setFieldsValue({
            food_tag: categoryName,
          });
        }
      } catch (error) {
        console.error('カテゴリーの追加に失敗しました:', error);
      }
    } else if (isCategoryEditModalOpen) {
      try {
        const result: any = await updateTagAPI(selectedTag, categoryName);
        if (result.success === true) {
          message.success('カテゴリーの更新に成功しました');
          handleCategoryCancel();
          const tagsRes: any = await getTagsAPI();
          setTags(tagsRes);
          setSelectedCategory(categoryName);
          searchForm.setFieldsValue({
            food_tag: categoryName,
          });
          getList();
        }
      } catch (error) {
        console.error('カテゴリーの更新に失敗しました:', error);
      }
    } else {
      return;
    }
    handleCategoryCancel();
  };
  const handleCategoryEditOpen = () => {
    // 处理添加料理カテゴリー的逻辑
    console.log('添加料理カテゴリー:', categoryName);
    setCategoryName(selectedCategory);
    setIsCategoryEditModalOpen(true);
  };

  const handleCategoryCancel = () => {
    setIsCategoryModalOpen(false);
    setIsCategoryEditModalOpen(false);
  };

  const handleFoodOk = () => {
    // 处理添加料理的逻辑
    console.log('添加料理:', foodName);
    setIsFoodModalOpen(false);
  };

  const handleFoodCancel = () => {
    setIsFoodModalOpen(false);
  };

  const handleAddFoodOk = async () => {
    if (selectedOption === 'type') {
      console.log('foodlist', foodList);
      console.log('selectFoodCategory', selectFoodCategory);
      if (selectFoodCategory === null) {
        message.error('料理カテゴリーを選択してください');
      } else {
        setSelectedOption('cuisine');
      }
    } else if (selectedOption === 'cuisine') {
      try {
        // 验证表单字段
        await foodAddForm.validateFields();
        // 如果验证通过，执行后续逻辑
        console.log('验证通过，提交表单');
        // 获取整个表单的数据
        const formData = foodAddForm.getFieldsValue(true);
        console.log('formData', formData);
        setSelectedOption('status');
      } catch (error) {
        // 如果验证失败，处理错误
        console.log('验证失败', error);
        message.error('料理の必須項目を確認してください');
        return;
      }
    } else if (selectedOption === 'status') {
      if (!selectFoodCategory) {
        message.error('料理カテゴリーを選択してください');
        return;
      }
      try {
        // 验证表单字段
        await foodAddForm.validateFields();
        // 如果验证通过，执行后续逻辑
        console.log('验证通过，提交表单');
        // 从组件的状态中获取 formData
        const formData = foodAddForm.getFieldsValue(true);
        console.log(formData);
        if (!formData) {
          message.error('请先填写表单');
          return;
        }
        // 在 foodList 中找到对应 name 的 tag
        const tagItem = tags.find((item) => item.name === selectFoodCategory);
        console.log('tagItem', tagItem.tag);
        const result = await addFoodAPI(
          Number(formData.id),
          formData.name,
          formData.like_ratio_desc,
          formData.month_saled,
          formData.unit,
          formData.food_tag_list,
          formData.price,
          formData.description,
          tagItem.tag, // 使用 tagItem.tag
          formData.count,
          selectFoodStatus,
          30009257
        );
        if (result.success === true) {
          // 料理口味功能管理
          const newFlavors = formData.flavors;
          if (newFlavors && newFlavors.length > 0) {
            // 调用 getFlavorsAPI 获取所有 fid 和 flavor 对象的数组
            const flavors = await getFlavorsAPI();
            console.log('flavors', flavors);
            // 如果 flavors 为空，跳过后续操作直接调用 addFlavorsAPI 和 updateFoodFlavorsAPI
            if (!flavors || flavors.length === 0) {
              const result = await addFlavorsAPI(newFlavors);
              if (result.success === true) {
                const insertIds = result.insertIds;
                await updateFoodFlavorsAPI(Number(formData.id), insertIds);
              } else {
                message.error('食品の風味の追加に失敗しました');
              }
            } else {
              // 获取现有风味的名称列表
              const existingFlavorNames = flavors.map(
                (flavor) => flavor.flavor
              );

              // 过滤出 newFlavors 中不包含在现有风味中的项
              const flavorsToAdd = newFlavors.filter(
                (flavor) => !existingFlavorNames.includes(flavor)
              );

              const existingFlavorFids =
                newFlavors
                  .filter((flavor) => existingFlavorNames.includes(flavor))
                  .map((flavor) => {
                    const existingFlavor = flavors.find(
                      (f) => f.flavor === flavor
                    );
                    return existingFlavor ? existingFlavor.fid : null;
                  })
                  .filter((fid) => fid !== null) || [];

              // 如果有需要添加的新风味，调用 addFlavorsAPI
              if (flavorsToAdd && flavorsToAdd.length > 0) {
                const result = await addFlavorsAPI(flavorsToAdd);
                console.log('result', result);
                if (result.success === true) {
                  const insertIds = result.insertIds;
                  const allFlavorFids = [...existingFlavorFids, ...insertIds];
                  await deleteFlavorAPI(currentFood.id);
                  await updateFoodFlavorsAPI(currentFood.id, allFlavorFids);
                } else {
                  message.error('食品の風味の追加に失敗しました');
                }
              } else {
                // 否则直接调用 deleteFlavorAPI 和 updateFoodFlavorsAPI
                await deleteFlavorAPI(Number(formData.id));
                await updateFoodFlavorsAPI(
                  Number(formData.id),
                  existingFlavorFids
                );
              }
            }
          } else {
            // 如果 newFlavors 为空，直接调用 deleteFlavorAPI
            await deleteFlavorAPI(Number(formData.id));
          }
          console.log('fileList:', fileList);
          try {
            if (fileList.length > 0) {
              const file = fileList[0];
              console.log('Uploading file:', file);
              const uploadResult: any = await uploadFoodPictureAPI(file);
              console.log('Upload result:', uploadResult);
              const { url, filename, mimetype, size } = uploadResult;
              console.log('Upload result:', uploadResult);
              // 调用 updateFileAPI 更新文件信息
              const response = await updateFoodPictureAPI(
                Number(formData.id),
                url,
                filename,
                mimetype,
                size
              );
              console.log('Update file response:', response);
            } else {
              console.log('No file selected');
            }
          } catch (error) {
            message.error('アップロードが失敗しました');
            console.error('アップロードが失敗しました:', error);
          }

          message.success('料理追加に成功しました');
          handleTopLevelCancel();
          getList();
        }
      } catch (error) {
        // 如果验证失败，处理错误
        console.log('验证失败', error);
        message.error('料理の必須項目を確認してください');
        setSelectedOption('cuisine');
        return;
      }
    }
  };

  const handleTopLevelOk = async () => {
    if (selectedOption === 'type') {
      console.log('foodlist', foodList);
      console.log('currentFood', currentFood.id);
      if (selectFoodCategory === null) {
        message.success('更新に成功しました');
      } else {
        // 在 foodList 中找到对应 name 的 tag
        const tagItem = tags.find((item) => item.name === selectFoodCategory);
        console.log('tagItem', tagItem.tag);
        const result = await updateFoodTagAPI(currentFood.id, tagItem.tag);
        if (result.status === true) {
          message.success('更新に成功しました');
          // 成功后重新获取 foodList
          getList();
        } else {
          message.error('更新に失敗しました');
        }
      }
    } else if (selectedOption === 'cuisine') {
      try {
        // 验证表单字段
        await foodForm.validateFields();
        // 如果验证通过，执行后续逻辑
        console.log('验证通过，提交表单');
        // 获取整个表单的数据
        const formData = foodForm.getFieldsValue(true);
        // 料理口味功能管理
        const newFlavors = formData.flavors;
        if (newFlavors && newFlavors.length > 0) {
          // 调用 getFlavorsAPI 获取所有 fid 和 flavor 对象的数组
          const flavors = await getFlavorsAPI();
          console.log('flavors', flavors);
          // 如果 flavors 为空，跳过后续操作直接调用 addFlavorsAPI 和 updateFoodFlavorsAPI
          if (!flavors || flavors.length === 0) {
            const result = await addFlavorsAPI(newFlavors);
            if (result.success === true) {
              const insertIds = result.insertIds;
              await updateFoodFlavorsAPI(currentFood.id, insertIds);
            } else {
              message.error('食品の風味の追加に失敗しました');
            }
          } else {
            // 获取现有风味的名称列表
            const existingFlavorNames = flavors.map((flavor) => flavor.flavor);

            // 过滤出 newFlavors 中不包含在现有风味中的项
            const flavorsToAdd = newFlavors.filter(
              (flavor) => !existingFlavorNames.includes(flavor)
            );

            const existingFlavorFids =
              newFlavors
                .filter((flavor) => existingFlavorNames.includes(flavor))
                .map((flavor) => {
                  const existingFlavor = flavors.find(
                    (f) => f.flavor === flavor
                  );
                  return existingFlavor ? existingFlavor.fid : null;
                })
                .filter((fid) => fid !== null) || [];

            // 如果有需要添加的新风味，调用 addFlavorsAPI
            if (flavorsToAdd && flavorsToAdd.length > 0) {
              const result = await addFlavorsAPI(flavorsToAdd);
              console.log('result', result);
              if (result.success === true) {
                const insertIds = result.insertIds;
                const allFlavorFids = [...existingFlavorFids, ...insertIds];
                await deleteFlavorAPI(currentFood.id);
                await updateFoodFlavorsAPI(currentFood.id, allFlavorFids);
              } else {
                message.error('食品の風味の追加に失敗しました');
              }
            } else {
              // 否则直接调用 deleteFlavorAPI 和 updateFoodFlavorsAPI
              await deleteFlavorAPI(currentFood.id);
              await updateFoodFlavorsAPI(currentFood.id, existingFlavorFids);
            }
          }
        } else {
          // 如果 newFlavors 为空，直接调用 deleteFlavorAPI
          await deleteFlavorAPI(currentFood.id);
        }

        console.log('current', currentFood.id);
        // 调用 updateFoodAPI 函数，并将表单数据作为参数传递
        const result = await updateFoodAPI(
          currentFood.id,
          Number(formData.id),
          formData.name,
          formData.like_ratio_desc,
          formData.month_saled,
          formData.unit,
          formData.price,
          formData.description,
          formData.food_tag_list
        );
        console.log('result', result);
        if (result.status === true) {
          try {
            if (fileList[0].uid !== '-1') {
              const file = fileList[0];
              console.log('Uploading file:', file);
              const uploadResult: any = await uploadFoodPictureAPI(file);
              console.log('Upload result:', uploadResult);
              const { url, filename, mimetype, size } = uploadResult;
              console.log('Upload result:', uploadResult);
              // 调用 updateFileAPI 更新文件信息
              const response = await updateFoodPictureAPI(
                Number(formData.id),
                url,
                filename,
                mimetype,
                size
              );
              console.log('Update file response:', response);
            }
          } catch (error) {
            message.error('アップロードが失敗しました');
            console.error('アップロードが失敗しました:', error);
          }

          message.success('更新に成功しました');
          // 成功后重新获取 foodList
          getList();
        } else {
          message.error('更新に失敗しました');
        }
      } catch (error) {
        // 如果验证失败，处理错误
        console.log('验证失败', error);
        message.error('入力内容を確認してください');
        return;
      }
      console.log('edit food');
    } else if (selectedOption === 'status') {
      if (selectFoodStatus === null) {
        message.success('更新に成功しました');
      } else {
        // 处理 selectFoodStatus 不为 null 的情况
        console.log('selectFoodStatus is not null');
        const result = await updateStatusAPI(currentFood.id, selectFoodStatus);
        console.log('result', result);
        if (result.status === true) {
          message.success('更新に成功しました');
          handleTopLevelCancel();
          // 成功后重新获取 foodList
          getList();
        } else {
          message.error('更新に失敗しました');
        }
      }
    }
  };

  const handleTopLevelCancel = () => {
    setIsFoodModalVisible(false);
    setIsAddFoodModalOpen(false);
    console.log('取消添加料理カテゴリー', selectFoodCategory);
    setSelectFoodCategory(null);
    setSelectFoodStatus(null);
    setCurrentFood(null);
    setCurrentPage(1);
    setFileList([]);
    setSelectedOption('type');
    // 重置表单内容
    foodForm.resetFields();
    foodAddForm.resetFields();
  };

  // const { channelList } = useChannel()
  // 准备列数据
  // 定义状态枚举
  const status = {
    1: <Tag color="warning">ステージ</Tag>,
    2: <Tag color="success">オンライン</Tag>,
  };

  const memoizedColumns = useMemo(
    () => [
      {
        title: '料理カテゴリー',
        dataIndex: 'categoryName',
        width: 120,
      },
      {
        title: '料理ID',
        dataIndex: 'id',
        width: 120,
      },
      {
        title: '料理名',
        dataIndex: 'name',
        width: 120,
      },
      {
        title: 'セットプランID',
        dataIndex: 'comboId',
        width: 120,
      },
      {
        title: 'カバー',
        dataIndex: 'picture',
        width: 120,
        render: (picture) => {
          return (
            <img
              src={picture ? picture : '/uploads/default/noPicture.jpg'}
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'contain',
                objectPosition: 'center',
              }}
              alt=""
            />
          );
        },
      },
      {
        title: '好評度',
        dataIndex: 'like_ratio_desc',
        width: 120,
      },
      {
        title: '月販売数',
        dataIndex: 'month_saled',
        width: 120,
      },
      {
        title: '単位',
        dataIndex: 'unit',
        width: 120,
      },
      {
        title: 'タグリスト',
        dataIndex: 'food_tag_list',
        width: 300,
        render: (tags) => tags.join(', '),
      },
      {
        title: '価格',
        dataIndex: 'price',
        width: 150,
      },
      {
        title: '説明',
        dataIndex: 'description',
        width: 150,
      },
      {
        title: '風味',
        dataIndex: 'flavors',
        width: 120,
        render: (flavors) => <div>{flavors.join(', ')}</div>,
      },

      {
        title: '状態',
        dataIndex: 'status',
        width: 150,
        render: (text) => {
          if (text === 'online') {
            return 'オンライン';
          } else if (text === 'stage') {
            return 'ステージ';
          }
          return text;
        },
      },
      {
        title: '操作',
        width: 120,
        render: (data) => {
          return (
            <Space size="middle">
              <Button
                type="default"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={showAddFoodModal}
              />
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => showFoodModal(data)}
              />

              <Popconfirm
                title="料理を削除"
                description={
                  selectedRowKeys.length > 0
                    ? ` ${selectedRowKeys.length}件の料理を削除してもよろしいですか？`
                    : 'この料理を削除してもよろしいですか？'
                }
                onConfirm={() => onConfirm(data)}
                okText="はい"
                cancelText="いいえ"
              >
                <Button
                  type="primary"
                  danger
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          );
        },
      },
    ],
    // selectedRowKeys が変更された場合にのみ、memoizedColumns を再計算する
    [selectedRowKeys]
  );
  const comboColumns = useMemo(
    () => [
      {
        title: 'セットプランID',
        dataIndex: 'comboId',
        width: 120,
      },
      {
        title: 'セットプラン名',
        dataIndex: 'comboName',
        width: 150,
      },
      {
        title: 'カバー',
        dataIndex: 'picture',
        width: 120,
        render: (picture) => {
          return (
            <img
              src={picture ? picture : '/uploads/default/noPicture.jpg'}
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'contain',
                objectPosition: 'center',
              }}
              alt=""
            />
          );
        },
      },
      {
        title: '状態',
        dataIndex: 'status',
        width: 150,
        render: (text) => {
          if (text === 'online') {
            return 'オンライン';
          } else if (text === 'stage') {
            return 'ステージ';
          }
          return text;
        },
      },
      {
        title: '操作',
        width: 120,
        render: (data) => {
          return (
            <Space size="middle">
              <Button
                type="default"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={showAddFoodModal}
              />
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => showFoodModal(data)}
              />

              <Popconfirm
                title="料理を削除"
                description={
                  selectedRowKeys.length > 0
                    ? ` ${selectedRowKeys.length}件の料理を削除してもよろしいですか？`
                    : 'この料理を削除してもよろしいですか？'
                }
                onConfirm={() => onConfirm(data)}
                okText="はい"
                cancelText="いいえ"
              >
                <Button
                  type="primary"
                  danger
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          );
        },
      },
    ],
    // selectedRowKeys が変更された場合にのみ、memoizedColumns を再計算する
    [selectedRowKeys]
  );
  const [navbarColumns, setNavbarColumns] = useState(memoizedColumns);
  const [dataSource, setDataSource] = useState([]);
  async function handleNavClick(e) {
    setSelectedKey(e.key);
    console.log('Selected Key:', e.key);
    if (e.key === 'combo') {
      // combo の詳細情報を取得
      const { comboDetails } = await getAllComboDetailAPI(Number(shopId));
      console.log('comboDetails', comboDetails);
      setNavbarColumns(comboColumns);
      setDataSource(comboDetails);
    } else if (e.key === 'list') {
      setNavbarColumns(memoizedColumns);
      setDataSource(flattenedData);
    }
  }

  useEffect(() => {
    setNavbarColumns(memoizedColumns);
  }, [memoizedColumns]);

  const handleResize =
    (index) =>
      (e, { size }) => {
        const newColumns = [...navbarColumns];
        newColumns[index] = {
          ...newColumns[index],
          width: size.width,
        };
        setNavbarColumns(newColumns);
      };

  const mergedColumns = navbarColumns.map((col, index) => ({
    ...col,
    onHeaderCell: (item) => ({
      width: item.width,
      onResize: handleResize(index),
      isResizable: index !== navbarColumns.length - 1,
    }),
  }));
  // 筛选功能
  // 1. 准备参数
  const [reqData, setReqData] = useState({
    id: '',
    foodName: '',
    food_tag: '',
    status: '',
    page: 1,
    per_page: 3,
  });

  useEffect(() => {
    getList(); //需要修改成根据检索条件获取数据
  }, []);

  // 使用 useEffect 监听 currentFood 的变化
  useEffect(() => {
    if (currentFood) {
      foodForm.setFieldsValue(currentFood);
    }
  }, [currentFood]);

  // 2. 获取筛选数据
  const handleSearch = async (formValue) => {
    console.log(formValue);
    // 3. 把表单收集到数据放到参数中(不可变的方式)
    setReqData({
      ...reqData,
      id: formValue.id,
      foodName: formValue.foodName,
      food_tag: formValue.food_tag,
      status: formValue.status,
      // begin_pubdate: formValue.date[0].format('YYYY-MM-DD'),
      // end_pubdate: formValue.date[1].format('YYYY-MM-DD'),
    });
    try {
      const res = await searchFoodsAPI(formValue);
      console.log('response', res);
      console.log('response', res.resultFood);
      setFoodList(res.resultFood);
      // 计算每一个 foods 下的长度
      const totalCount = res.resultFood.reduce(
        (acc, item) => acc + (item.foods ? item.foods.length : 0),
        0
      );
      setReqData({
        ...reqData,
        page: 1,
      });
      setCount(totalCount);
    } catch (error) {
      console.error('Error fetching food data:', error);
    }
  };

  // 分页
  const onPageChange = (page) => {
    console.log(page);
    // 修改参数依赖项 引发数据的重新获取列表渲染
    setReqData({
      ...reqData,
      page,
    });
  };

  // 删除
  const onConfirm = async (data) => {
    // 检查 selectedRowKeys 是否有值
    console.log('selectedRowKeys:', selectedRowKeys);
    const keysToUse = selectedRowKeys.length > 0 ? selectedRowKeys : [data.id];
    const result = await deleteFoodsAPI(keysToUse);
    console.log('result', result);
    try {
      if (result.status === true) {
        const deleteCount =
          selectedRowKeys.length > 0 ? selectedRowKeys.length : 1;
        message.success(` ${deleteCount}件の料理を削除に成功しました`);
        setSelectedRowKeys([]);
        // 成功后重新获取 foodList
        getList();
      } else {
        message.error('削除に失敗しました');
      }
    } catch (error) {
      // 如果验证失败，处理错误
      console.log('验证失败', error);
      message.error('選択した料理を確認してください');
      return;
    }
  };

  const handleCategoryDelete = async () => {
    // 检查 foodlist 中是否有 name 等于 selectedCategory 的项
    const foodExists = foodList.some((food) => food.name === selectedCategory);
    if (foodExists) {
      message.error('選択したカテゴリーに関連する料理が存在しています');
      return;
    }
    const selectedTag = tags.find((tag) => tag.name === selectedCategory)?.tag;
    console.log('selectedTag', selectedTag);
    const result = await deleteTagAPI(selectedTag);
    console.log('result', result);
    try {
      if (result.status === true) {
        message.success(
          ` ${selectedCategory}という名のカテゴリーを削除に成功しました`
        );
        const tagsRes: any = await getTagsAPI();
        setTags(tagsRes);
        setSelectedCategory('');
        searchForm.setFieldValue('food_tag', '');
        getList();
      } else {
        message.error('削除に失敗しました');
      }
    } catch (error) {
      // 如果验证失败，处理错误
      console.log('验证失败', error);
      message.error('選択したカテゴリーを確認してください');
      return;
    }
  };

  // 展平数据
  const flattenedData = foodList.flatMap((category) =>
    category.foods.map((food) => ({
      ...food,
      categoryName: category.name, // 添加类别名称
    }))
  );
  useEffect(() => {
    if (foodList && foodList.length > 0) {
      const flattened = foodList.flatMap((category) =>
        category.foods.map((food) => ({
          ...food,
          categoryName: category.name, // 添加类别名称
        }))
      );
      setDataSource(flattened);
    }
  }, [foodList]);

  const onValuesChange = (changedValues, allValues) => {
    setCurrentFood((prevState) => ({
      ...prevState,
      ...allValues,
    }));
  };

  const handleCategorySelectChange = (value) => {
    const selectedItem = tags.find((item) => item.tag === value);
    const selectedName = selectedItem ? selectedItem.name : '';
    console.log('selectedName', selectedName);
    setSelectedCategory(selectedName);
    if (value === '') {
      {/* @ts-ignore */ }
      document.activeElement.blur();
      searchForm.resetFields(['food_tag']);
    } else {
      searchForm.setFieldsValue({
        food_tag: selectedName,
      });
    }
  };

  const handleUpdateItem = (newItem, index, formItem) => {
    const updatedItems = Array.isArray(currentFood?.[formItem])
      ? currentFood[formItem]
      : [];
    if (
      Number(index) === Number(updatedItems.length) ||
      updatedItems.length === 0
    ) {
      // 添加新项
      updatedItems.push(newItem);
      foodAddForm.setFieldsValue({
        [formItem]: updatedItems,
      });
      foodForm.setFieldsValue({
        [formItem]: updatedItems,
      });
    } else if (Number(index) < Number(updatedItems.length)) {
      // 更新现有项
      updatedItems[index] = newItem;
    } else {
      console.error('Index out of bounds');
      return;
    }
    setCurrentFood({
      ...currentFood,
      [formItem]: updatedItems,
    });
    console.log('updatedItems', updatedItems);
  };

  const handleDeleteItem = (index, formItem) => {
    const updatedItems = currentFood[formItem];
    if (Number(index) < Number(updatedItems.length)) {
      // 删除对应项
      updatedItems.splice(index, 1);
    } else {
      console.error('Index out of bounds');
      return;
    }
    setCurrentFood({
      ...currentFood,
      [formItem]: updatedItems,
    });
    foodAddForm.setFieldsValue({
      [formItem]: updatedItems,
    });
    // 更新 foodForm 表单的值
    foodForm.setFieldsValue({
      [formItem]: updatedItems,
    });
    console.log('updatedItems', updatedItems);
  };

  const handlePanelClick = (event) => {
    console.log('Panel clicked');
    if (event.target.classList.contains('ant-collapse-content-box')) {
      return;
    }
    setReqData({
      ...reqData,
      per_page: reqData.per_page === 3 ? 5 : 3,
    });
  };

  return (
    console.log('currentFood', currentFood),
    (
      <div className="shopManagement">
        <Card
          className='shop-management-card'
          title={
            <ManagementNavbar onNavClick={handleNavClick} />
          }
        />
        <Card
        // title={
        //   <Breadcrumb
        //     items={[
        //       { title: <Link to={'/'}>ホーム</Link> },
        //       { title: '料理リスト' },
        //     ]}
        //   />
        // }
        // style={{ marginBottom: 20 }}
        >
          <Collapse defaultActiveKey={['1']}>
            {/* @ts-ignore */}
            <Panel header="検索条件" key="1" onClick={handlePanelClick}>
              <Form
                initialValues={{ status: '' }}
                onFinish={handleSearch}
                form={searchForm}
                onClick={(event) => event.stopPropagation()} // 阻止事件冒泡
              >
                <Form.Item label="状態" name="status">
                  <Radio.Group>
                    <Radio value={''}>すべて</Radio>
                    <Radio value={'stage'}>ステージ</Radio>
                    <Radio value={'online'}>オンライン</Radio>
                  </Radio.Group>
                </Form.Item>
                {/* 料理カテゴリー検索機能 */}
                <Form.Item
                  label="料理カテゴリー"
                  name="food_tag"
                  className="food_tag"
                >
                  <Select
                    placeholder="料理カテゴリーを選択してください"
                    value={selectedCategory}
                    style={{ width: 270 }}
                    onChange={handleCategorySelectChange}
                  >
                    <Option value="">料理カテゴリーを選択してください</Option>
                    {tags.map((item, index) => (
                      <Option key={index} value={item.tag}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>

                  <Space size="middle">
                    <Button
                      type="default"
                      shape="circle"
                      icon={<PlusOutlined />}
                      onClick={handleFoodTagOpen}
                    />
                    {selectedCategory && (
                      <>
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={handleCategoryEditOpen}
                        />
                        <Popconfirm
                          title="料理カテゴリーを削除"
                          description={
                            'この料理カテゴリーを削除してもよろしいですか？'
                          }
                          onConfirm={handleCategoryDelete}
                          okText="はい"
                          cancelText="いいえ"
                        >
                          <Button
                            type="primary"
                            danger
                            shape="circle"
                            icon={<DeleteOutlined />}
                          />
                        </Popconfirm>
                      </>
                    )}
                  </Space>
                </Form.Item>
                <Form.Item label="料理ID" name="id">
                  <Input style={{ width: 200 }} />
                </Form.Item>
                <Form.Item label="料理名" name="foodName">
                  <Input style={{ width: 200 }} />
                </Form.Item>
                {/* <Form.Item label="日付" name="date"> */}
                {/* 传入locale属性 控制中文显示*/}
                {/* <RangePicker locale={locale}></RangePicker>
            </Form.Item> */}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: 40 }}
                  >
                    検索
                  </Button>
                </Form.Item>
              </Form>
            </Panel>
          </Collapse>
        </Card>
        {/* 表格区域 */}
        <Card title={`検索条件に基づいて ${count} 件の結果が見つかりました：`}>
          <Table
            components={{
              header: {
                cell: ResizableTitle,
              },
            }}
            rowKey="id"
            columns={mergedColumns as any}
            dataSource={dataSource}
            pagination={{
              current: reqData.page,
              total: count,
              pageSize: reqData.per_page,
              onChange: onPageChange,
            }}
            rowSelection={rowSelection}
          />
        </Card>

        <Modal
          title="料理新規追加"
          open={isAddFoodModalOpen}
          onOk={handleAddFoodOk}
          onCancel={handleTopLevelCancel}
          wrapClassName="food-modal-class"
          okText="確認"
          cancelText="キャンセル"
        >
          <Radio.Group onChange={onOptionChange} value={selectedOption}>
            <Radio value="type">料理カテゴリー</Radio>
            <Radio value="cuisine">料理</Radio>
            <Radio value="status">ステータス</Radio>
          </Radio.Group>
          {selectedOption === 'type' && (
            <div className="flex-container addFood-container">
              <span>現在の料理カテゴリー: </span>
              <TagDropdown
                Categorys={tags}
                defalutCategory={null}
                onCategorySelect={handleFoodCategorySelect}
                selectedCategory={selectFoodCategory}
              />
            </div>
          )}
          <div
            style={{ display: selectedOption === 'cuisine' ? 'block' : 'none' }}
          >
            <Form
              form={foodAddForm}
              initialValues={{}}
              onValuesChange={onValuesChange}
            >
              <div style={{ display: currentPage === 1 ? 'block' : 'none' }}>
                <Form.Item
                  label="料理ID"
                  name="id"
                  rules={[
                    { required: true, message: '料理IDを入力してください' },
                    {
                      pattern: /^[0-9]+$/,
                      message: '料理IDは数字でなければなりません',
                    },
                  ]}
                >
                  <Input placeholder="料理IDを入力" />
                </Form.Item>
                <Form.Item
                  label="料理名"
                  name="name"
                  rules={[
                    { required: true, message: '料理名を入力してください' },
                  ]}
                >
                  <Input placeholder="料理の名前を入力" />
                </Form.Item>
                <Form.Item
                  label="カバー"
                  name="picture"
                  rules={[
                    {
                      required: true,
                      message: 'カバーをアップデートしてください',
                    },
                  ]}
                >
                  <div className="avatar-container">
                    <FoodImageUpload
                      fileList={fileList}
                      onChange={handleImageChange}
                      maxCount={1}
                      allowedTypes={[
                        'image/jpeg',
                        'image/png',
                        'image/gif',
                        'image/bmp',
                        'image/webp',
                      ]}
                    ></FoodImageUpload>
                  </div>
                </Form.Item>
                <Form.Item label="好評度" name="like_ratio_desc">
                  <Input placeholder="好評度を入力" />
                </Form.Item>
                <Form.Item
                  label="月販売数"
                  name="month_saled"
                  rules={[
                    {
                      required: false,
                      message: '月販売数を入力してください',
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: '料理IDは数字でなければなりません',
                    },
                  ]}
                >
                  <Input placeholder="月販売数を入力" />
                </Form.Item>
              </div>
              <div style={{ display: currentPage === 2 ? 'block' : 'none' }}>
                <Form.Item
                  label="単位"
                  name="unit"
                  rules={[
                    { required: true, message: '単位を入力してください' },
                  ]}
                >
                  <Input placeholder="単位を入力" />
                </Form.Item>
                <Form.Item label="タグリスト" name="food_tag_list">
                  <TaglistDropdown
                    items={
                      Array.isArray(currentFood?.food_tag_list)
                        ? currentFood.food_tag_list
                        : []
                    }
                    itemName="タグ"
                    defalutText="タグリストを確認してください"
                    defalutAddText="タグリストに追加"
                    onUpdateItem={(newItem, index) =>
                      handleUpdateItem(newItem, index, 'food_tag_list')
                    }
                    onDeleteItem={(index) =>
                      handleDeleteItem(index, 'food_tag_list')
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="価格"
                  name="price"
                  rules={[
                    { required: true, message: '価格を入力してください' },
                    {
                      pattern: /^[0-9]+$/,
                      message: '料理IDは数字でなければなりません',
                    },
                  ]}
                >
                  <Input placeholder="価格を入力" />
                </Form.Item>
                <Form.Item label="説明" name="description">
                  <Input placeholder="説明を入力" />
                </Form.Item>
                <Form.Item label="風味" name="flavors">
                  <TaglistDropdown
                    items={
                      Array.isArray(currentFood?.flavors)
                        ? currentFood.flavors
                        : []
                    }
                    itemName="風味"
                    defalutText="風味を確認してください"
                    defalutAddText="風味を追加"
                    onUpdateItem={(newItem, index) =>
                      handleUpdateItem(newItem, index, 'flavors')
                    }
                    onDeleteItem={(index) => handleDeleteItem(index, 'flavors')}
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={10} // 总数
                  onChange={(page, pageSize) => {
                    setCurrentPage(page);
                    setPageSize(pageSize);
                  }}
                />
              </Form.Item>
            </Form>
          </div>

          {selectedOption === 'status' && (
            <div className="flex-container">
              <span>現在の料理ステータス: </span>
              <StatusDropdown
                defalutStatus="stage"
                onStatusSelect={handleFoodStatusSelect}
                selectedStatus={selectFoodStatus}
              />
            </div>
          )}
        </Modal>
        <Modal
          title="料理カテゴリーを追加"
          open={isCategoryModalOpen}
          onOk={handleCategoryOk}
          onCancel={handleCategoryCancel}
          okText="確認"
          cancelText="キャンセル"
        >
          <Input
            placeholder="料理カテゴリーの名前を入力"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </Modal>
        <Modal
          title="料理カテゴリーを編集"
          open={isCategoryEditModalOpen}
          onOk={handleCategoryOk}
          onCancel={handleCategoryCancel}
          okText="確認"
          cancelText="キャンセル"
        >
          <Input
            placeholder={selectedCategory}
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </Modal>

        {/* 添加料理的 Modal */}
        <Modal
          title="料理を追加"
          open={isFoodModalOpen}
          onOk={handleFoodOk}
          onCancel={handleFoodCancel}
          okText="確認"
          cancelText="キャンセル"
        >
          <Input
            placeholder="料理の名前を入力"
            value={foodName}
            onChange={(e) => setFoodName(e.target.value)}
          />
        </Modal>

        <Modal
          title="编辑"
          open={isFoodModalVisible}
          onOk={handleTopLevelOk}
          onCancel={handleTopLevelCancel}
          wrapClassName="food-modal-class"
          okText="確認"
          cancelText="キャンセル"
        >
          <Radio.Group onChange={onOptionChange} value={selectedOption}>
            <Radio value="type">料理カテゴリー</Radio>
            <Radio value="cuisine">料理</Radio>
            <Radio value="status">ステータス</Radio>
          </Radio.Group>
          {selectedOption === 'type' && currentFood !== null && (
            <div className="flex-container">
              <span>現在の料理カテゴリー: </span>
              <TagDropdown
                Categorys={tags}
                defalutCategory={currentFood.categoryName}
                onCategorySelect={handleFoodCategorySelect}
                selectedCategory={selectFoodCategory}
              />
            </div>
          )}
          {selectedOption === 'cuisine' && currentFood !== null && (
            <Form
              form={foodForm}
              initialValues={currentFood}
              onValuesChange={onValuesChange}
            >
              <div
                style={{
                  display: currentFood.currentPage === 1 ? 'block' : 'none',
                }}
              >
                <Form.Item
                  label="料理ID"
                  name="id"
                  rules={[
                    { required: true, message: '料理IDを入力してください' },
                    {
                      pattern: /^[0-9]+$/,
                      message: '料理IDは数字でなければなりません',
                    },
                  ]}
                >
                  <Input placeholder="料理IDを入力" />
                </Form.Item>
                <Form.Item
                  label="料理名"
                  name="name"
                  rules={[
                    { required: true, message: '料理名を入力してください' },
                  ]}
                >
                  <Input placeholder="料理の名前を入力" />
                </Form.Item>
                <Form.Item
                  label="カバー"
                  name="picture"
                  rules={[
                    {
                      required: true,
                      message: 'カバーをアップデートしてください',
                    },
                  ]}
                >
                  <div className="avatar-container">
                    <FoodImageUpload
                      fileList={fileList}
                      onChange={handleImageChange}
                      maxCount={1}
                      allowedTypes={[
                        'image/jpeg',
                        'image/png',
                        'image/gif',
                        'image/bmp',
                        'image/webp',
                      ]}
                    ></FoodImageUpload>
                  </div>
                </Form.Item>
                <Form.Item label="好評度" name="like_ratio_desc">
                  <Input placeholder="好評度を入力" />
                </Form.Item>
                <Form.Item
                  label="月販売数"
                  name="month_saled"
                  rules={[
                    {
                      required: false,
                      message: '月販売数を入力してください',
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: '料理IDは数字でなければなりません',
                    },
                  ]}
                >
                  <Input placeholder="月販売数を入力" />
                </Form.Item>
              </div>
              <div
                style={{
                  display: currentFood.currentPage === 2 ? 'block' : 'none',
                }}
              >
                <Form.Item
                  label="単位"
                  name="unit"
                  rules={[
                    { required: true, message: '単位を入力してください' },
                  ]}
                >
                  <Input placeholder="単位を入力" />
                </Form.Item>
                <Form.Item label="タグリスト" name="food_tag_list">
                  <TaglistDropdown
                    items={
                      Array.isArray(currentFood?.food_tag_list)
                        ? currentFood.food_tag_list
                        : []
                    }
                    itemName="タグ"
                    defalutText="タグリストを確認してください"
                    defalutAddText="タグリストに追加"
                    onUpdateItem={(newItem, index) =>
                      handleUpdateItem(newItem, index, 'food_tag_list')
                    }
                    onDeleteItem={(index) =>
                      handleDeleteItem(index, 'food_tag_list')
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="価格"
                  name="price"
                  rules={[
                    { required: true, message: '価格を入力してください' },
                    {
                      pattern: /^[0-9]+$/,
                      message: '料理IDは数字でなければなりません',
                    },
                  ]}
                >
                  <Input placeholder="価格を入力" />
                </Form.Item>
                <Form.Item label="説明" name="description">
                  <Input placeholder="説明を入力" />
                </Form.Item>
                <Form.Item label="風味" name="flavors">
                  <TaglistDropdown
                    items={
                      Array.isArray(currentFood?.flavors)
                        ? currentFood.flavors
                        : []
                    }
                    itemName="風味"
                    defalutText="風味を確認してください"
                    defalutAddText="風味を追加"
                    onUpdateItem={(newItem, index) =>
                      handleUpdateItem(newItem, index, 'flavors')
                    }
                    onDeleteItem={(index) => handleDeleteItem(index, 'flavors')}
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <Pagination
                  current={currentFood.currentPage}
                  pageSize={currentFood.pageSize}
                  total={10} // 总数
                  onChange={(page, pageSize) => {
                    setCurrentFood({
                      ...currentFood,
                      currentPage: page,
                      pageSize,
                    });
                  }}
                />
              </Form.Item>
            </Form>
          )}
          {selectedOption === 'status' && currentFood !== null && (
            <div className="flex-container">
              <span>現在の料理ステータス: </span>
              <StatusDropdown
                defalutStatus={currentFood.status}
                onStatusSelect={handleFoodStatusSelect}
                selectedStatus={selectFoodStatus}
              />
            </div>
          )}
        </Modal>
      </div>
    )
  );
};

export default Shop;
