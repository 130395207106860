import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const QRCodeGenerator = () => {
  const url = 'https://6106-240f-71-d9aa-1-edb3-4be5-35df-c7e7.ngrok-free.app';
  const loginInfo = {
    mobile: '13211111111',
    code: '246810',
    url: 'https://6106-240f-71-d9aa-1-edb3-4be5-35df-c7e7.ngrok-free.app',
  };

  const qrCodeValue = JSON.stringify(loginInfo);

  return (
    <div>
      <h1>扫描二维码登录</h1>
      <QRCodeCanvas
        value={`${url}?loginInfo=${encodeURIComponent(qrCodeValue)}`}
      />
    </div>
  );
};

export default QRCodeGenerator;

// import React from 'react';
// import { QRCodeCanvas } from 'qrcode.react';

// const QRCodeGenerator = () => {
//   const url = 'https://www.google.com';

//   return (
//     <div>
//       <h1>扫描二维码登录</h1>
//       <QRCodeCanvas value={url} />
//     </div>
//   );
// };

// export default QRCodeGenerator;