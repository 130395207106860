import './index.scss';
import { Select, Tooltip } from 'antd';

const Dropdown = ({ flavors, onFlavorSelect }) => {
  const { Option } = Select;
  const handleFlavorChange = (value) => {
    onFlavorSelect(value);
    console.log(value);
  };
  return (
    <div className="dropdown">
      <h3>味を選ぶことができます</h3>
      <Select
        style={{ width: 80 }}
        placeholder={flavors[0]}
        onSelect={handleFlavorChange}
      >
        {flavors.map((flavor, index) => (
          <Option key={index} value={flavor}>
            <Tooltip title={flavor}>
              <span>{flavor}</span>
            </Tooltip>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default Dropdown;
