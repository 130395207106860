import React from 'react';
import { Resizable } from 'react-resizable';
import './index.scss';

const clearSelection = () => {
  if (window.getSelection) {
    const selection = window.getSelection();
    if (selection) {
      if (selection.empty) {
        // 在 Chrome 浏览器清空误选的文本
        selection.empty();
      } else if (selection.removeAllRanges) {
        // 在 Firefox 浏览器下清空误选的文本
        selection.removeAllRanges();
      }
    }
  } else if (document.selection && document.selection.empty) {
    // 在 IE 浏览器下清空误选的文本
    document.selection.empty();
  }
};

const ResizableTitle = (props) => {
  const { onResize, width, isResizable,...restProps } = props;

  const handleResizeStart = () => {
    document.body.classList.add('resizing');
  };

  const handleResizeStop = (e, { size }) => {
    document.body.classList.remove('resizing');
    onResize(e, { size });
  };


  if (!width || !isResizable) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => e.stopPropagation()}
        />
      }
      onResize={onResize}
      onResizeStart={handleResizeStart}
      onResizeStop={handleResizeStop}
      draggableOpts={{
        enableUserSelectHack: false,
        onMouseDown: () => {
          // 解决在 Windows 操作系统下，Edge 浏览器 和 Chrome 浏览器，松开鼠标按键，依然能拖动
          clearSelection();
        },
      }}
      minConstraints={[50, 0]} // 设置最小宽度为 50 像素
      maxConstraints={[Infinity, 0]} // 设置最大宽度为无限大
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default ResizableTitle;
