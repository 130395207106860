import { request } from '../../src/utils/request';

export async function getAmazonSearchAPI(keyword: string, sponsorFilter?: boolean) {
    try {
        // クエリパラメータを構築
        const queryParams = new URLSearchParams();
        queryParams.append('keyword', keyword);
        if (sponsorFilter !== undefined) {
            queryParams.append('sponsorFilter', sponsorFilter.toString());
        }

        // リクエストを送信
        const response = await request({
            url: `/api/searchLowestPrice?${queryParams.toString()}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: 10000, // タイムアウト時間を設定
        });

        // レスポンスデータを読み取る
        const data: any = response;
        console.log('data:', data);

        // レスポンスデータに成功メッセージが含まれているかを確認
        if (data) {
            return { statusCode: 200, message: data.message, ProductList: data.output };
        } else {
            throw new Error(data.error || 'Failed to fetch amazon search result');
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function handlerLoadMoreAPI(sponsorFilter?: boolean) {
    try {
        // クエリパラメータを構築（sponsorFilter はオプション）
        const queryParams = new URLSearchParams();
        if (sponsorFilter !== undefined) {
            queryParams.append('sponsorFilter', sponsorFilter.toString());
        }
        const url = queryParams.toString() ? `/api/loadMore?${queryParams.toString()}` : '/api/loadMore';

        // リクエストを送信
        const response = await request({
            url,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: 5000, // タイムアウト時間を設定
        });

        // レスポンスデータを読み取る
        const data: any = response;
        console.log('data:', data);

        // レスポンスデータに成功メッセージが含まれているかを確認
        if (data) {
            return { statusCode: 200, message: data.message, ProductList: data.output };
        } else {
            throw new Error(data.error || 'Failed to fetch amazon search result');
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function getAmazonKeywordAPI(keyword: string) {
    try {
        // クエリパラメータを構築
        const queryParams = new URLSearchParams();
        queryParams.append('keyword', keyword);

        // リクエストを送信
        const response = await request({
            url: `/api/get-amazonKeyword?${queryParams.toString()}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: 10000, // タイムアウト時間を設定
        });

        // レスポンスデータを読み取る
        const data: any = response;
        console.log('data:', data);

        // レスポンスデータをチェックして返す
        if (data) {
            return { statusCode: 200, message: data.message, data: data.data };
        } else {
            throw new Error(data.error || 'Failed to fetch amazon keyword result');
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}
