import "./index.scss";
import { Card, Form, Input, Button, message, Spin, AutoComplete } from "antd";
import { AlertOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAmazonSearchAPI } from "../../apis/AmazonSearch";
import { useState, useRef, useEffect } from "react";
import { setProductList } from "../../store/modules/amazonProduct";
import LoaderAnimation from "./LoaderAnimation";
import AmazonProductMenu from "../AmazonProductMenu";
import classNames from "classnames";
import { contactUsAPI } from '../../apis/user';
import { useSpring, animated, config } from '@react-spring/web';
import { getAmazonKeywordAPI } from '../../apis/AmazonSearch';

const { Search } = Input;

const AmazonLowestPrice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  // loaderPhase状態を追加、初期フェーズは "gather"
  const [loaderPhase, setLoaderPhase] = useState<'gather' | 'disperse' | 'complete'>('gather');
  // 検索成功後、amazonProductMenuコンテンツを表示（ルーティング切り替えに依存しない）
  const [showProductMenu, setShowProductMenu] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [iconActive, setIconActive] = useState(false);
  const [sponsorFilter, setsponsorFilter] = useState(undefined);
  const [isSearching, setIsSearching] = useState(false);
  const [visible, setVisible] = useState(false);
  const [sixStarVisible, setSixStarVisible] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [form] = Form.useForm();
  const [shake, setShake] = useState(false);
  // flipped が true の場合、裏面に回転
  const [flipped, setFlipped] = useState(false);
  const [scaleFactor, setScaleFactor] = useState(window.innerWidth < 400 ? 3 : 3.5);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      // scaleFactor を更新：幅が400未満の場合は3、それ以外は3.5を使用
      setScaleFactor(window.innerWidth < 400 ? 3 : 3.5);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // react-spring アニメーション設定：opacity は表裏の切り替え、transform は3D回転効果を実現
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
    config: config.default,
  });
  const handleSubmit = async () => {
    try {
      // 全てのフィールドを検証し、未入力の項目がある場合は例外をスローします
      await form.validateFields();
      const { name, email, message: userMessage } = form.getFieldsValue();
      // 検証に合格した場合は、その後の送信ロジックを実行します
      setSubmitClicked(true);
      // Fire-and-forget
      contactUsAPI(name, email, userMessage)
        .then((response) => {
          console.log("API response:", response);
          // レスポンス後に成功メッセージをポップアップ表示
          if (response.success) {
            message.success("メールの送信に成功しました");
          } else {
            message.error("メール送信に失敗しました");
          }
        })
      setSixStarVisible(true);
      // 1秒後に visible を false に設定し、さらに1秒後に flipped を true に設定
      setTimeout(() => {
        setVisible(false);
        setTimeout(() => {
          setFlipped(true);
        }, 1000);
      }, 1000);
      // ここに送信成功後の処理ロジックを追加します...
    } catch (error) {
      message.error("必須項目をすべて入力してください");
      // シェイク効果を追加します
      setShake(true);
      setTimeout(() => setShake(false), 500);
    }
  };
  const handleSixStarClick = () => {
    setSixStarVisible(false);
    setSubmitClicked(false);
    form.resetFields();
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSearch(event.target.value);
    }
  };
  const onSearch = async (keyword: string) => {
    if (isSearching) return;
    // 入力が空または空白のみの場合、検索ボックスにフォーカスを戻して検索ロジックを終了
    if (keyword.trim() === "") {
      message.warning('検索キーワードを入力してください');
      searchRef.current?.focus();
      return;
    }
    searchRef.current?.blur(); // 検索ボックスからフォーカスを外す
    setIsSearching(true);
    setLoading(true);
    // アニメーションフェーズをgatherにリセット
    setLoaderPhase("gather");
    try {
      const result = await getAmazonSearchAPI(keyword, sponsorFilter);
      console.log('Search API result:', result);
      // setResults(result.amazonSearchResult);
      if (result.message === 'searchSuccessfully') {
        dispatch(setProductList(result.ProductList));
        // 検索成功後、amazonProductMenuを表示
        setShowProductMenu(true);
        setLoaderPhase("disperse");
        // アニメーション再生完了まで1秒待機してからloadingをfalseに設定
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.error('Search API error:', error);
      setLoading(false);
      message.error('検索中にエラーが発生しました');
    }
    finally {
      setIsSearching(false);
      searchRef.current?.focus();
    }
  };
  const handleIconClick = () => {
    // ref を使用して入力ボックスのインスタンスを取得してフォーカスする
    searchRef.current?.focus();
    setIconActive(pre => !pre);
  };
  const handleFilterClick = () => {
    // filterActive の状態を切り替える
    setFilterActive(!filterActive);
    if (!iconActive && filterActive == false) {
      setIconActive(true);
    } else if (iconActive && filterActive === false) {
      setIconActive(true);
    }
    else {
      setIconActive(false);
    }
    // sponsorFilter を undefined と true の間で切り替える
    setsponsorFilter(filterActive ? undefined : true);
    console.log('条件フィルターがクリックされました');
  };
  // 画像を事前に読み込む
  useEffect(() => {
    const images = [
      '/uploads/default/loadingback.jpg',
      '/uploads/default/loadingfront.png',
      '/uploads/default/loading.jpg',
      '/uploads/default/contactUs.png',
    ];

    images.forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  const onSearchKeyWord = async (keyword: string, ignoreDropdownCheck: boolean = false) => {
    // ここで入力内容をチェックし、空の場合はAPIを呼び出さない
    if (!keyword || keyword.trim() === "") {
      console.warn("検索キーワードが空です");
      return;
    }
    // onChange 経由で呼び出されておらず、かつ dropdownOpen が true の場合は処理を終了する
    if (!ignoreDropdownCheck && dropdownOpen) return;
    try {
      const result = await getAmazonKeywordAPI(keyword);
      console.log('Amazon Keyword API result:', result);
      if (result && Array.isArray(result.data)) {
        setSuggestions(result.data);
        setDropdownOpen(true);
      }
      // 必要に応じて戻り値を処理（例：状態の更新やメッセージの表示）
    } catch (error) {
      console.error('Error calling getAmazonKeywordAPI:', error);
    }
  };
  // デバウンスタイマーの参照
  const debounceTimer = useRef<number | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // 前のタイマーをクリア
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    // 新たにタイマーをセットし、1秒間新しい入力がなければ onSearchKeyWord を呼び出す
    debounceTimer.current = window.setTimeout(() => {
      if (value && value.trim() !== "") {
        onSearchKeyWord(value, true);
      }
    }, 1000);
  };

  return (
    <div>
      {/* loadingがtrueの時LoaderAnimationを表示し、集合アニメーション完了後phaseをcompleteに更新 */}
      {loading && <LoaderAnimation phase={loaderPhase} onGatherComplete={() => setLoaderPhase("complete")} />}
      {/* バックグラウンドでamazonProductMenuをレンダリングし、検索成功時にコンテンツを表示 */}
      {showProductMenu && <AmazonProductMenu onClose={() => setShowProductMenu(false)} sponsorFilter={sponsorFilter} />}
      {!showProductMenu && <div className="login">
        <div className={classNames("Amazon-search")}>
          <div className="coinContainer" onClick={() => setFlipped(prev => !prev)}>
            <animated.div
              className={classNames("search-icon", "coin front", { active: iconActive })}
              onClick={handleIconClick}
              style={{
                opacity: opacity.to(o => 1 - o),
                transform,
              }}
            >
              <img src='/uploads/default/searchIcon.jpeg' alt="コインの表面" />
            </animated.div>
            <animated.div
              className={classNames("coin", "back")}
              style={{
                opacity,
                transform: transform.to(t => `${t} rotateY(180deg) scale(${scaleFactor})`),
              }}
            >
              <img src='/uploads/default/up.png' alt="コインの裏面" />
            </animated.div>
          </div>
          <div className="search-container">
            {/* <Spin spinning={loading}> */}
            <AutoComplete
              style={{ width: 200 }}
              options={suggestions.map(item => ({ value: item }))}
              open={suggestions.length > 0}
              onSelect={() => {
                // ドロップダウンオプションを選択した時、onSearchKeyWordを呼び出し、ドロップダウンメニューを閉じてsuggestionsをクリア
                setDropdownOpen(false);
                setSuggestions([]);
              }}
              // ドロップダウンメニューの開閉状態を追跡
              onDropdownVisibleChange={(open) => {
                // 開こうとしてsuggestionsが空の場合は閉じたままにする
                if (open && suggestions.length === 0) {
                  setDropdownOpen(false);
                } else {
                  setDropdownOpen(true);
                }
              }}
            >
              <Search
                ref={searchRef}
                autoFocus
                placeholder="最安商品検索"
                onSearch={onSearch}
                onKeyPress={handleKeyPress}
                style={{ width: 200 }}
                onClick={() => {
                  console.log(dropdownOpen);
                  // dropdownOpenがtrueの場合、falseに更新してsuggestionsをクリア
                  if (dropdownOpen) {
                    setDropdownOpen(false);
                    setSuggestions([]);
                    return;
                  }
                  const currentValue = searchRef.current?.input?.value;
                  onSearchKeyWord(currentValue);
                }}
                onChange={handleInputChange}
                onTouchStart={() => {
                  if (dropdownOpen) {
                    setDropdownOpen(false);
                    setSuggestions([]);
                    return;
                  }
                  const currentValue = searchRef.current?.input?.value;
                  onSearchKeyWord(currentValue);
                }}
              />
            </AutoComplete>
            {/* </Spin> */}
            <div
              className="filter-icon"
              onClick={handleFilterClick}
            >
              {filterActive ? <AlertOutlined /> : <svg className="AlertFilled" viewBox="0 0 100 100">
                <image xlinkHref="/uploads/default/AlertFilled.png" width="100" height="100" />
              </svg>}
              {!filterActive && <span className="non-advertised-goods">スポンサー商品抜き</span>}
            </div>
          </div>
        </div>
        <div className="contactContainer">
          <div
            className={classNames("contactOverlay", visible && "visible")}
            onClick={() => setVisible(false)}
          />
          <div className={classNames("contactUs", !sixStarVisible && "visible")}
            onClick={() => setVisible(true)} >
            <img className="pen" src="/uploads/default/pen.png" width="100" height="100" />
          </div>
          <div className={classNames("sixStarContainer", sixStarVisible && "visible")} onClick={handleSixStarClick}>
            <img src="/uploads/default/sixStar.png" alt="sixStar" className="sixStar" />
            <img src="/uploads/default/letter.png" alt="letter" className="letter" />
          </div>
          <div className={classNames("contactPanel", visible && "visible")}>
            <div
              className="close-btn"
              onClick={() => setVisible(false)}>
              ×
            </div>
            <img src="/uploads/default/table.png" className="background-image" />
            <Form form={form} className={classNames("contact-form", { shake })} validateMessages={{ required: '' }}>
              <Form.Item name="name" rules={[{ required: true }]}>
                <Input placeholder="お名前" />
              </Form.Item>
              <Form.Item name="email" rules={[{ required: true }]}>
                <Input placeholder="メールアドレス" />
              </Form.Item>
              <Form.Item name="message" rules={[{ required: true }]}>
                <Input.TextArea placeholder="ご要望" rows={4} />
              </Form.Item>
              <Form.Item>
                <div className="submit-btn-container">
                  <img
                    src="/uploads/default/chop.png"
                    className={classNames("submit-btn", { hidden: submitClicked })}
                    onClick={handleSubmit}
                    alt="chop"
                  />
                  <img
                    src="/uploads/default/seal.png"
                    alt="seal"
                    className={classNames({ hidden: !submitClicked })}
                  />
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default AmazonLowestPrice;