import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import './Comments.scss';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { message } from 'antd';
import { Link } from 'react-router-dom';
import {
  getCommentsAPI,
  updateCommentAPI,
  deleteCommentAPI,
  getLikeAPI,
  updateLikeAPI,
} from '../../apis/comment';
import { getUserInfoAPI } from '../../apis/user';

// 导航 Tab 数组
const tabs = [
  { type: 'hot', text: '推薦順' },
  { type: 'time', text: '時間順' },
];

// 封装请求数据的Hook

function useGetList() {
  // 获取接口数据渲染
  const [commentList, setCommentList] = useState([]);

  useEffect(() => {
    // 请求数据
    async function getList() {
      // axios请求数据
      const comments: any = await getCommentsAPI();
      console.log('commentsResponse:', comments);
      // 获取点赞数据
      const likes: any = await getLikeAPI();
      console.log('likesResponse:', likes);
      const commentList = comments.map(comment => {
        const likeData = likes.find(like => like.rpid === comment.rpid);
        return {
          ...comment,
          like: likeData ? likeData.like : comment.like,
        };
      });
      setCommentList(_.orderBy(commentList, 'like', 'desc')); // 默认按点赞数排序
    }
    getList();
  }, []);

  return {
    commentList,
    setCommentList,
  };
}

// 封装Item组件

function Item({ item, onDel, user, userLikes }) {
  const [likeCount, setLikeCount] = useState(item.like);
  const [likeColor, setLikeColor] = useState(''); // 新增状态来管理颜色
  // 监听 item.like 的变化，并更新 likeCount 状态
  useEffect(() => {
    setLikeCount(item.like);
    console.log('userLikes:', userLikes);
    if (userLikes.includes(item.rpid)) {
      setLikeColor('wheat'); // 如果用户已经点赞，设置颜色为wheat
    } else {
      setLikeColor(''); // 如果用户没有点赞，取消颜色
    }
  }, [item.like, userLikes, item.rpid]);
  const handleReplyLikeClick = async () => {
    const result = await updateLikeAPI(user.userId, item.rpid);
    console.log('result:', result);
    if (likeColor === 'wheat') {
      setLikeCount(likeCount - 1);
      setLikeColor(''); // 取消颜色
    } else {
      setLikeCount(likeCount + 1);
      setLikeColor('wheat'); // 设置颜色为wheat
    }
  };
  return (
    <div className="reply-item">
      {/* 头像 */}
      <div className="root-reply-avatar">
        <div className="bili-avatar">
          <img
            className="bili-avatar-img"
            alt=""
            src={
              item.user.avatar ? item.user.avatar : '/uploads/default/user.jpg'
            }
          />
        </div>
      </div>

      <div className="content-wrap">
        {/* 用户名 */}
        <div className="user-info">
          <div className="user-name">{item.user.uname}</div>
        </div>
        {/* 评论内容 */}
        <div className="root-reply">
          <span className="reply-content">{item.content}</span>
          <div className="reply-info">
            {/* 评论时间 */}
            <span className="reply-time">{item.ctime}</span>
            {/* 评论数量 */}
            <span
              className="reply-like"
              onClick={handleReplyLikeClick}
              style={{ color: likeColor }}
            >
              いいね！
              <br />
              (●'◡'●)👍:{likeCount}
            </span>
            {/* 条件：user.id === item.user.id */}
            {user.userId === item.user.userId && (
              <span className="delete-btn" onClick={() => onDel(item.rpid)}>
                削除
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const Comments = () => {
  // 渲染评论列表
  // 1. 使用useState维护list
  // const [commentList, setCommentList] = useState(_.orderBy(list, 'like', 'desc'))

  const [visible, setVisible] = useState(false);
  const [avatar, setAvatar] = useState(
    localStorage.getItem('avatar') || '/uploads/user.jpg'
  );
  const [userId, setUserId] = useState(() => {
    const storedUserId = localStorage.getItem('userId');
    return storedUserId ? parseInt(storedUserId, 10) : null;
  });
  const [user, setUser] = useState({
    userId: userId,
    avatar: avatar,
    uname: '',
  });
  const [userLikes, setUserLikes] = useState([]);
  const { commentList, setCommentList } = useGetList(); //此处是结构，所以是大括号为了解构对象

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response: any = await getUserInfoAPI(userId);
        if (response.status === 200) {
          const { uname } = response.user;
          setUser((prevUser) => ({
            ...prevUser,
            uname,
          }));
        } else {
          console.error('Failed to fetch user info:', response.message);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    const fetchUserLikes = async () => {
      try {
        const userLikes: any = await getLikeAPI(userId);
        console.log('userLikes:', userLikes);
        setUserLikes(userLikes.userLikes);
      } catch (error) {
        console.error('Error fetching user likes:', error);
      }
    };

    fetchUserInfo();
    fetchUserLikes();
  }, [userId]);

  // 删除功能
  const handleDel = async (id) => {
    try {
      console.log('Received id:', id); // 添加日志
      const response: any = await deleteCommentAPI(id);
      if (response.status === 200) {
        // 对commentList做过滤处理
        setCommentList(commentList.filter((item) => item.rpid !== id));
        // 削除成功のメッセージを表示
        message.success('コメントが正常に削除されました');
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  // tab切换功能
  // 1. 点击谁就把谁的type记录下来
  // 2. 通过记录的type和每一项遍历时的type做匹配 控制激活类名的显示
  const [type, setType] = useState('hot');
  const [order, setOrder] = useState('desc');

  const handleTabChange = (type) => {
    console.log(type);
    setType(type);
    // 基于列表的排序
    if (type === 'hot') {
      // 根据点赞数量排序
      // lodash
      setCommentList(_.orderBy(commentList, 'like', 'desc'));
      setVisible(false);
    } else {
      // 根据创建时间排序
      setCommentList(_.orderBy(commentList, 'ctime', 'desc'));
      setVisible(false);
    }
  };

  const handleSortToggle = (newType) => {
    if (newType !== type) {
      setType(newType);
      const descOrder = 'desc';
      setOrder(descOrder);
      console.log('descOrder:', descOrder);
      setVisible(false);
      // 基于列表的排序
      if (newType === 'hot') {
        // 根据点赞数量排序
        // lodash
        setCommentList(_.orderBy(commentList, 'like', descOrder));
      } else {
        // 根据创建时间排序
        setCommentList(_.orderBy(commentList, 'ctime', descOrder));
      }
    } else {
      setVisible(!visible);
      const newOrder = order === 'desc' ? 'asc' : 'desc';
      setOrder(newOrder);
      console.log('New Order:', newOrder);

      // 基于列表的排序
      if (type === 'hot') {
        // 根据点赞数量排序
        // lodash
        setCommentList(_.orderBy(commentList, 'like', newOrder));
      } else {
        // 根据创建时间排序
        setCommentList(_.orderBy(commentList, 'ctime', newOrder));
      }
    }
  };

  // 发表评论
  const [content, setContent] = useState('');
  const inputRef = useRef(null);
  const handlPublish = async () => {
    // 检查输入框内容是否为空
    if (!content.trim()) {
      message.error('コメント内容を入力してください');
      return;
    }
    try {
      // 发送 API 请求
      const response: any = await updateCommentAPI(userId, content);
      console.log('response:', response.rpid);

      // 检查响应状态码
      if (response.rpid) {
        // 1. 清空输入框的内容
        setContent('');
        // 2. 重新聚焦  dom(useRef) - focus
        inputRef.current.focus();
        // 3. 添加新评论到 commentList
        const newComment = {
          rpid: response.rpid,
          user: { userId: userId, avatar: avatar, uname: user.uname },
          content,
          ctime: dayjs().format('MM-DD HH: mm'),
          like: 0,
        };
        setCommentList([newComment, ...commentList]);
        message.success('コメントが正常に投稿されました');
      } else {
        console.error('Failed to update comment:', response.data.error);
      }
    } catch (error) {
      console.error('Error updating comment:', error);
    }
  };

  return (
    <div className="app">
      {/* 导航 Tab */}
      <div className="reply-navigation">
        <ul className="nav-bar">
          <li className="nav-title">
            <span className="nav-title-text">評論</span>
            {/* 评论数量 */}
            <span className="total-reply">{commentList.length}</span>
          </li>
          <li className="nav-sort">
            {/* 高亮类名： active */}
            {tabs.map((item) => (
              <ul>
                <span
                  className="nav-title-text"
                  onClick={() => handleSortToggle(item.type)}
                >
                  <span
                    key={item.type}
                    className={classNames('arrow', {
                      expand: type === item.type && visible,
                    })}
                  ></span>
                </span>
                <span
                  key={item.type}
                  onClick={() => handleTabChange(item.type)}
                  className={classNames('nav-item', {
                    active: type === item.type,
                  })}
                >
                  {item.text}
                </span>
              </ul>
            ))}
          </li>
          <Link to="/" className="link-block">
            <div className="reply-box-back">
              <div className="send-text">戻る</div>
            </div>
          </Link>
        </ul>
      </div>

      <div className="reply-wrap">
        {/* 发表评论 */}
        <div className="box-normal">
          {/* 当前用户头像 */}
          <div className="reply-box-avatar">
            <div className="bili-avatar">
              <img
                className="bili-avatar-img"
                src={avatar ? avatar : '/uploads/default/user.jpg'}
                alt="用户头像"
              />
            </div>
          </div>
          <div className="reply-box-wrap">
            {/* 评论框 */}
            <textarea
              className="reply-box-textarea"
              placeholder="コメントを入力してください"
              ref={inputRef}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            {/* 发布按钮 */}
            <div className="reply-box-send" onClick={handlPublish}>
              <div className="send-text">投稿</div>
            </div>
          </div>
        </div>
        {/* 评论列表 */}
        <div className="reply-list">
          {/* 评论项 */}
          {commentList.map((item) => (
            <Item key={item.id} item={item} onDel={handleDel} user={user} userLikes={userLikes} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Comments;
