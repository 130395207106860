import classNames from 'classnames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveIndex } from '../../store/modules/takeaway';
import './index.scss';

const Menu = () => {
  const { foodsList, activeIndex } = useSelector((state: any) => state.foods);
  const dispatch = useDispatch();
  useEffect(() => {
    // 组件加载时将第一个菜单项设置为激活状态
    if (foodsList.length > 0) {
      dispatch(changeActiveIndex(0));
    }
  }, [dispatch, foodsList]);

  const menus = foodsList
    .filter(
      (item) =>
        item.foods && item.foods.some((food) => food.status === 'online')
    )
    .map((item) => ({ tag: item.tag, name: item.name }));
  return (
    <nav className="list-menu">
      {/* 添加active类名会变成激活状态 */}
      {menus.map((item, index) => {
        return (
          <div
            // 提交action切换激活index
            onClick={() => dispatch(changeActiveIndex(index))}
            key={item.tag}
            className={classNames(
              'list-menu-item',
              activeIndex === index && 'active'
            )}
          >
            {item.name}
          </div>
        );
      })}
    </nav>
  );
};

export default Menu;
