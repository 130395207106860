import foodsReducer from './modules/takeaway'
import { configureStore } from '@reduxjs/toolkit'
import userReducer from './modules/user'
import orderReducer from './modules/order'

const store = configureStore({
  reducer: {
    foods: foodsReducer,
    user: userReducer,
    orders : orderReducer
  },
})

export default store