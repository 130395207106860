// 用户相关的所有请求
import { request } from "../utils/request";

export function getCommentsAPI() {
  return request({
    url: "/api/get-allComments",
    method: "GET",
  });
}

export async function updateCommentAPI(userId, content) {
  try {
    const response = await request({
      url: "/api/insert-comment",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        userId,
        content,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log("data:", data);

    // 检查响应数据是否包含成功消息
    if (data.message === "Comment added successfully") {
      return { rpid: data.rpid };
    } else {
      throw new Error(data.error || "Failed to update comment");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export function deleteCommentAPI(rpid) {
  return request({
    url: "/api/delete-comment",
    method: "DELETE",
    data: { rpid },
  });
}

export function getLikeAPI(userId?: number | null): Promise<any> {
  const url = userId
    ? `/api/like?userId=${userId}`
    : "/api/like";

  return request({
    url: url,
    method: "GET",
  });
}

export async function updateLikeAPI(userId, rpid) {
  try {
    const response = await request({
      url: "/api/like",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        userId,
        rpid,
      },
    });

    // 读取响应数据
    const data: any = response;
    console.log("data:", data);

    // 检查响应数据是否包含成功消息
    if (data.success) {
      return { success: true };
    } else {
      throw new Error(data.error || "Failed to update like");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}