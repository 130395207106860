import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSpring, animated } from '@react-spring/web';
import './index.scss';
const AmazonProductItem = ({
  id,
  image,
  href,
  aria,
  coupon,
  price,
  shipping_cost,
  sponsor,
}) => {
  const { shopId } = useSelector(
    (state: any) => state.user.shop
  );
  const [showPreview, setShowPreview] = useState(false);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  return (
    <dd
      className="amazon-cate-goods"
      onClick={() => {
        window.open(href, '_blank');
      }}
    >
      <div className="goods-img-wrap">
        <a href={href} target="_blank" rel="noopener noreferrer" onClick={(e) => {
          if (showPreview) {
            e.preventDefault();
          }
        }}
          onTouchStart={(e) => {
            if (showPreview) {
              e.preventDefault();
            }
          }}>
          <img
            src={image || '/uploads/default/noPicture.jpg'}
            alt={aria}
            className="goods-img"
            onMouseOver={() => setShowPreview(true)}
            onMouseOut={() => setShowPreview(false)}
            onTouchStart={() => setShowPreview(true)}
            onTouchEnd={() => setShowPreview(false)}
            onTouchCancel={() => setShowPreview(false)}
            onContextMenu={(e) => e.preventDefault()}  // 右クリックメニューを防止
          />
        </a>
      </div>
      <div className="goods-info">
        <div className="goods-desc">
          <div className="goods-title">{aria}</div>
          <div className="goods-detail">
            <div className="goods-detail-text"></div>
          </div>
        </div>
        <div className="goods-price-count">
          <div className="goods-price">
            {price.toLocaleString()}
            <span className="goods-price-unit">&nbsp;円</span>
          </div>
        </div>
      </div>
      {showPreview && (
        <div className="img-preview-overlay">
          <img src={image || '/uploads/default/noPicture.jpg'} alt={aria} className='preview' />
          <img src="/uploads/default/snake.svg" alt="snake" className="snake snake1" />
          <img src="/uploads/default/snake.svg" alt="snake" className="snake snake2" />
          <img src="/uploads/default/snake.svg" alt="snake" className="snake snake3" />
          <img src="/uploads/default/snake.svg" alt="snake" className="snake snake4" />
        </div>
      )}
    </dd>
  );
};

export default AmazonProductItem;
