import { request } from '../../src/utils/request';

export function getTagsAPI() {
  return request({
    url: '/api/get-tags',
    method: 'GET',
  });
}
export function updateTagAPI(tag, name) {
  return request({
    url: '/api/update-tag',
    method: 'POST',
    data: {
      tag,
      name,
    },
  });
}

export function addTagAPI(name) {
  return request({
    url: '/api/add-tag',
    method: 'POST',
    data: {
      name,
    },
  });
}

export async function deleteTagAPI(tag) {
  try {
    const response: any = await request({
      url: '/api/delete-tag',
      method: 'DELETE',
      params: {
        tag,
      },
    });

    // 检查响应数据是否包含成功消息
    if (response.message === 'タグが正常に削除されました') {
      return { status: true };
    } else {
      throw new Error(response.error || 'Failed to delete tag');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
