import { Upload, Button, message, Avatar } from 'antd';
import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import './index.scss';

const ImageUpload = ({ maxCount, allowedTypes, onChange, fileList }) => {
  console.log('fileList', fileList);
  const [isFileValid, setIsFileValid] = useState(true);
  
  const handleChange = ({ fileList }) => {
    if (isFileValid) {
      onChange(fileList);
    } else {
      onChange([]);
    }
  };

  const beforeUpload = (file) => {
    const isImage = allowedTypes.includes(file.type);
    if (!isImage) {
      message.error('画像ファイルのみアップロードできます');
      setIsFileValid(false);
      return true; // 阻止文件上传
    }
    setIsFileValid(true);
    return false; // 允许文件上传
  };
  
  return (
    <div className="foodImage-container">
      {fileList.length > 0 && (
        <Upload
          name="foodImage"
          listType="picture"
          maxCount={maxCount}
          fileList={fileList}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        ></Upload>
      )}
      <Upload
        name="uploadButton"
        listType="picture"
        maxCount={maxCount}
        fileList={fileList}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />}>料理画像を選択</Button>
      </Upload>
    </div>
  );
};

export default ImageUpload;
