import React, { useEffect } from 'react';
import './index.scss';

interface LoaderAnimationProps {
    phase: 'gather' | 'disperse' | 'complete';
    onGatherComplete?: () => void;
}

const LoaderAnimation: React.FC<LoaderAnimationProps> = ({ phase, onGatherComplete }) => {
    // 常に useEffect を呼び出す
    useEffect(() => {
        if (phase === 'gather' && onGatherComplete) {
            const timer = setTimeout(() => {
                onGatherComplete();
            }, 1000); // gather アニメーションの長さと一致
            return () => clearTimeout(timer);
        }
    }, [phase, onGatherComplete]);

    if (phase === 'complete') {
        return (
            <div className="complete-image-container">
                <img
                    className="complete-image"
                    src="/uploads/default/loadingback.jpg"
                />
                <img
                    className="complete-rotate"
                    src="/uploads/default/loadingfront.png"
                />
            </div>
        );
    }

    const cols = 6;
    const rows = 6;
    const totalPieces = cols * rows;
    const pieces = Array.from({ length: totalPieces }, (_, i) => i);

    // 各ピースのスタイルを生成
    const pieceStyles = pieces.map((i) => {
        const row = Math.floor(i / cols);
        const col = i % cols;
        const bgPosX = (col / (cols - 1)) * 100;
        const bgPosY = (row / (rows - 1)) * 100;
        const pieceCenterX = ((col + 0.5) / cols) * 100;
        const pieceCenterY = ((row + 0.5) / rows) * 100;
        const offsetXPercent = pieceCenterX - 50;
        const offsetYPercent = pieceCenterY - 50;
        const displacement = 50;
        const angle = Math.atan2(offsetYPercent, offsetXPercent);
        const dx = (displacement * Math.cos(angle)).toFixed(2) + 'px';
        const dy = (displacement * Math.sin(angle)).toFixed(2) + 'px';
        const rotationDeg = (angle * 180 / Math.PI).toFixed(2) + 'deg';

        return {
            style: {
                left: `${(col * 100) / cols}%`,
                top: `${(row * 100) / rows}%`,
                width: `${100 / cols}%`,
                height: `${100 / rows}%`,
                backgroundImage: `url(/uploads/default/loading.jpg)`,
                backgroundSize: `${cols * 100}% ${rows * 100}%`,
                backgroundPosition: `${bgPosX}% ${bgPosY}%`,
                '--tx': dx,
                '--ty': dy,
                '--rdeg': rotationDeg,
                '--tx-back': dx,
                '--ty-back': dy,
                '--rdeg-back': rotationDeg,
            } as React.CSSProperties,
        };
    });

    return (
        <div className="loader-container">
            {pieces.map((piece, index) => (
                <div
                    key={index}
                    className={`loader-piece ${phase === 'gather' ? 'gather' : 'disperse'}`}
                    style={pieceStyles[index].style}
                />
            ))}
        </div>
    );
};

export default LoaderAnimation;