import './index.scss';
import { Select, Tooltip, Button, Modal, Popconfirm, Input } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';

const Dropdown = ({
  items,
  itemName,
  defalutText,
  defalutAddText,
  onUpdateItem,
  onDeleteItem,
}) => {
  const { Option } = Select;
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleAddModalOk = () => {
    console.log('newitem', selectedItem);
    console.log('newindex', selectedIndex);
    onUpdateItem(selectedItem, selectedIndex);
    setIsAddModalVisible(false);
    setSelectedItem('');
  };
  const handleAddModalCancel = () => {
    setIsAddModalVisible(false);
  };

  const handleOptionClick = (value, option) => {
    console.log('value', value);
    console.log('option', option);
    if (value === defalutAddText) {
      setSelectedIndex(option.key);
      setSelectedItem('');
      setIsAddModalVisible(true);
    } else {
      setSelectedItem(value);
      setSelectedIndex(option.key);
      setIsEditModalVisible(true);
    }
  };

  const handleDeleteButtonClick = (e, index) => {
    e.stopPropagation();
    setSelectedIndex(index);
  };
  const handleEditModalOk = () => {
    console.log('newitem', selectedItem);
    console.log('newindex', selectedIndex);
    onUpdateItem(selectedItem, selectedIndex);
    setIsEditModalVisible(false);
    setSelectedItem('');
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setSelectedItem('');
  };
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    console.log('newindex', selectedIndex);
    onDeleteItem(selectedIndex);
  };
  const handleCancelDelete = (e) => {
    e.stopPropagation(); // 阻止事件传播
  };
  console.log('items', selectedItem);
  return (
    <div className="dropdown">
      <Select
        style={{ width: 250 }}
        placeholder={defalutText}
        onSelect={handleOptionClick}
        value={defalutText}
      >
        {items.length > 0 &&
          items.map((item, index) => (
            <Option
              key={index}
              value={item}
              // @ts-ignore
              onClick={() => handleOptionClick(item)}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Tooltip title={item}>
                  <span>{item}</span>
                </Tooltip>

                <Popconfirm
                  title="項目を削除"
                  description={'この項目を削除してもよろしいですか？'}
                  onConfirm={handleDeleteClick}
                  onCancel={handleCancelDelete}
                  okText="はい"
                  cancelText="いいえ"
                >
                  <Button
                    className="delete-button"
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={(e) => handleDeleteButtonClick(e, index)}
                  />
                </Popconfirm>
              </div>
            </Option>
          ))}
        <Option
          key={items.length || 1}
          value={defalutAddText}
          onClick={handleOptionClick}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Tooltip title={defalutAddText}>
              <span>{defalutAddText}</span>
            </Tooltip>
            <Button
              className="add-button"
              type="text"
              icon={<PlusOutlined />}
              // @ts-ignore
              onClick={handleOptionClick}
            />
          </div>
        </Option>
      </Select>
      <Modal
        title={`${itemName}を追加`}
        open={isAddModalVisible}
        onOk={handleAddModalOk}
        onCancel={handleAddModalCancel}
        okText="確認"
        cancelText="キャンセル"
      >
        <Input
          placeholder="タグを入力"
          value={selectedItem}
          onChange={(e) => setSelectedItem(e.target.value)}
        />
      </Modal>
      <Modal
        title={`${itemName}を編集`}
        open={isEditModalVisible}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}
        okText="確認"
        cancelText="キャンセル"
      >
        <Input
          placeholder={selectedItem}
          value={selectedItem}
          onChange={(e) => setSelectedItem(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default Dropdown;
