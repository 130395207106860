import './index.scss';
import { Select, Tooltip } from 'antd';

const Dropdown = ({
  Categorys,
  defalutCategory,
  onCategorySelect,
  selectedCategory,
}) => {
  const { Option } = Select;
  const handleCategoryChange = (value) => {
    onCategorySelect(value);
  };
  // 如果 defalutCategory 为 null，设置初始值为 "请选择料理类型"
  const placeholderText = defalutCategory || '料理カテゴリーを選択してください';
  return (
    console.log('Categorys', Categorys),
    (
      <div className="dropdown">
        <Select
          style={{ width: 150 }}
          placeholder={placeholderText}
          value={selectedCategory}
          onSelect={handleCategoryChange}
        >
          {Categorys.map((Category, index) => (
            <Option key={index} value={Category.name}>
              <Tooltip title={Category.name}>
                <span>{Category.name}</span>
              </Tooltip>
            </Option>
          ))}
        </Select>
      </div>
    )
  );
};

export default Dropdown;
