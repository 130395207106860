import { request } from '../utils/request';

export async function createPayPayQRCodeAPI(merchantPaymentId, amount, orderDescription) {
    try {
        const response = await request({
            url: '/api/create-qrcode',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                merchantPaymentId,
                amount,
                orderDescription,
            },
            timeout: 5000, // タイムアウト時間を設定
        });

        // レスポンスデータを読み取る
        const data: any = response;
        console.log('data:', data);

        // レスポンスデータに成功メッセージが含まれているか確認
        if (data.url) {
            return { success: true, url: data.url };
        } else {
            throw new Error(data.error || 'QRコードの作成に失敗しました');
        }
    } catch (error) {
        console.error('エラー:', error);
        throw error;
    }
}

export async function checkPayPayPaymentDetailsAPI(orderId: string) {
    try {
        const response = await request({
            url: '/api/check-paypay-paymentDetails',
            method: 'GET',
            params: {
                orderId,
            },
            timeout: 5000, // タイムアウト時間を設定
        });

        // レスポンスデータを読み取る
        const data: any = response;
        console.log('支払い詳細のレスポンスデータ:', data);

        // レスポンスデータに成功メッセージが含まれているか確認
        if (data) {
            return { data };
        } else {
            console.error('支払い詳細を取得できませんでした');
        }
    } catch (error) {
        console.error('PayPay 支払い詳細の取得中にエラーが発生しました:', error);
    }
}