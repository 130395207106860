import { useSelector, useDispatch } from 'react-redux';
import User from '../user';
import './index.scss';
import { Form, Input, Button, message } from 'antd';
import { updateAddress } from '../../store/modules/user';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getAddressRecordAPI, updateAddressAPI } from '../../apis/user';

const UpdateAddress = () => {
  const [form] = Form.useForm();
  const [postalCode, setPostalCode] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const address = useSelector((state: any) => state.user.address); // 假设地址信息存储在 Redux 的 state.user.address 中
  const { userInfo } = useSelector((state: any) => state.user);

  useEffect(() => {
    const fetchAddressRecord = async () => {
      try {
        const userId = userInfo.userId; // 替换为实际的 userId
        const addressRecord: any = await getAddressRecordAPI(userId);
        console.log('addressRecord', addressRecord);

        if (addressRecord && addressRecord.address) {
          const { address } = addressRecord;
          form.setFieldsValue({
            postalCodeInput: address.postalCodeInput,
            prefecture: address.prefecture,
            city: address.city,
            addressLine: address.addressLine,
            phoneNumber: address.phoneNumber,
          });
          setPostalCode(address.postalCode);
          // 更新 Redux 中的 address
          dispatch(updateAddress(address));
        } else {
          console.log('No address found, needs new address');
        }
      } catch (error) {
        console.error('Error fetching address record:', error);
      }
    };

    fetchAddressRecord();
  }, [form]);

  const handleBack = () => {
    navigate(-1); // 返回到上一步
  };
  const handleSubmit = async (values) => {
    console.log('addressId', address.addressId);
    try {
      // 调用 updateAddressAPI 方法
      const response = await updateAddressAPI(
        address.addressId,
        values.postalCodeInput,
        values.prefecture,
        values.city,
        values.addressLine,
        values.phoneNumber
      );

      // 处理表单提交逻辑
      console.log('Form Data Submitted: ', values);

      // 1. 提示一下用户
      if (response.data.message === 'Address updated successfully') {
        const updatedValues = {
          id: address.addressId,
          postalCodeInput: values.postalCodeInput,
          prefecture: values.prefecture,
          city: values.city,
          addressLine: values.addressLine,
          phoneNumber: values.phoneNumber,
        };
        // 调用 dispatch 的 setAddress 方法
        dispatch(updateAddress(updatedValues));

        // 处理表单提交逻辑
        message.success('住所が更新されました');
      } else {
        message.error('住所の更新に失敗しました');
      }
    } catch (error) {
      console.error('Error updating address:', error);
      message.error('住所の更新中にエラーが発生しました');
    }
  };

  const handlePostalCodeChange = (e) => {
    setPostalCode(e.target.value);
  };

  const handleAutoFillAddress = async () => {
    const postalCode = form.getFieldValue('postalCodeInput');
    try {
      const response = await axios.get(
        `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`
      );
      const data = response.data;
      if (data.results && data.results.length > 0) {
        const address = data.results[0];
        const { address1, address2, address3 } = address;
        console.log('data', address);
        form.setFieldsValue({
          prefecture: address1,
          city: `${address2} ${address3}`,
        });
      } else {
        console.error('No results found');
        message.error('無効な郵便番号が入力されました');
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      message.error('未知のエラーが発生しました');
    }
  };

  return (
    <div className="addressConfirmationContainer">
      <div className="scrollArea">
        <div className="header">
          <span className="text">配達先住所の確認</span>
          <User></User>
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="addressForm"
        >
          <Form.Item label="郵便番号" name="postalCode" required>
            <Input.Group compact>
              <Form.Item
                name="postalCodeInput"
                noStyle
                rules={[
                  { required: true, message: '郵便番号を入力してください' },
                  {
                    pattern: /^\d{7}$/,
                    message: '7桁の郵便番号を入力してください',
                  },
                ]}
              >
                <Input
                  style={{ width: 'calc(100% - 100px)' }}
                  onChange={handlePostalCodeChange}
                />
              </Form.Item>
              <Button
                type="primary"
                htmlType="button"
                style={{ width: '100px' }}
                onClick={handleAutoFillAddress}
              >
                住所を自動入力
              </Button>
            </Input.Group>
          </Form.Item>
          <Form.Item
            label="都道府県"
            name="prefecture"
            rules={[{ required: true, message: '都道府県を入力してください' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="市区町村"
            name="city"
            rules={[{ required: true, message: '市区町村を入力してください' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="住所"
            name="addressLine"
            rules={[{ required: true, message: '住所を入力してください' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="電話番号"
            name="phoneNumber"
            rules={[
              { required: true, message: '電話番号を入力してください' },
              {
                pattern: /^\d{10,11}$/,
                message: '有効な電話番号を入力してください',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType='submit'
            >
              住所を更新します
            </Button>
          </Form.Item>
        </Form>
        {/* 结算 or 起送 */}
        <Button type="primary" onClick={handleBack} className='backToHome'>
          前に戻る
        </Button>
      </div>
    </div>
  );
};

export default UpdateAddress;
