import classNames from "classnames";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCartFlavor,
  decreCount,
  increCount,
  clearCart,
  removeCartItem,
} from "../../store/modules/takeaway";
import Count from "../Count";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import CartDropdown from "../cartDropdown";
import { motion } from 'framer-motion';

const Cart = ({ foodsList }) => {
  const navigate = useNavigate();
  const { cartList } = useSelector((state: any) => state.foods);
  const { token, userInfo } = useSelector((state: any) => state.user);
  // 解构 foodsList 中的每个 food 对象
  const foodItems = foodsList.flatMap(({ foods }) =>
    foods.map(({ id, name, price, count, flavors }) => ({
      id,
      name,
      price,
      count,
      flavors,
    }))
  );
  console.log("fooditems", foodItems);

  const cartFlavors = cartList.flatMap((cartItem) => {
    const food = foodItems.find((foodItem) => foodItem.name === cartItem.name);
    return food ? food.flavors : [];
  });

  console.log("cartFlavors", cartFlavors);

  const matchingFoods = cartList.flatMap((cartItem) => {
    if (cartItem.flavors && cartItem.flavors.length > 0) {
      return foodItems.filter((foodItem) => foodItem.name === cartItem.name);
    }
    return [];
  });
  console.log("matchingFoods", matchingFoods);

  const matchingFoodsByName = matchingFoods.reduce((acc, foodItem) => {
    acc[foodItem.name] = foodItem;
    return acc;
  }, {});

  console.log("matchingFoodsByName", matchingFoodsByName);

  const handleFlavorSelect = (itemId, itemname, newFlavor) => {
    console.log("flavor", newFlavor);
    console.log("itemId", itemId);
    console.log("itemname", itemname);
    // 查找cartlist中是否存在与itemname匹配的项
    const foundItem = cartList.find(
      (cartItem) => cartItem.name === itemname && cartItem.id !== itemId
    );
    console.log("foundItem", foundItem);
    // 如果不存在匹配项，或者匹配项的flavors与newFlavor不一致，则调用changeCartFlavor
    if (!foundItem || foundItem.flavors !== newFlavor) {
      dispatch(
        changeCartFlavor({
          itemId,
          flavors: newFlavor,
        })
      );
    } else {
      dispatch(removeCartItem({ itemId }));
      dispatch(increCount({ id: foundItem.id }));
    }
  };

  // 计算总价
  const totalPrice = cartList.reduce((a, c) => a + c.price * c.count, 0);

  const dispatch = useDispatch();

  // 控制购物车打开关闭的状态
  const [visible, setVisible] = useState(false);

  const onShow = () => {
    if (cartList.length > 0 && !visible) {
      setVisible(true);
    } else if (cartList.length > 0 && visible) {
      setVisible(false);
    } else return;
  };
  const handleClick = () => {
    if (token && userInfo.userId) {
      navigate("/checkout"); // 如果有 token 和 userId，跳转到结算页面
    } else {
      navigate("/messageLogin"); // 否则，跳转到登录页面
    }
  };
  return (
    <div className="cartContainer">
      {/* 遮罩层 添加visible类名可以显示出来 */}
      <div
        className={classNames("cartOverlay", visible && "visible")}
        onClick={() => setVisible(false)}
      />
      <div className="cart">
        <motion.div
          id="cart-icon"
          initial={{ scale: 1, scaleX: -1 }}
          animate={{ scale: 1.5}}
          transition={{ duration: 0.5 }}
          className="cart-icon"
        >
          🛒
        </motion.div>
        {/* fill 添加fill类名购物车高亮*/}
        {/* 购物车数量 */}
        <div
          onClick={onShow}
          className={classNames("icon", cartList.length > 0 && "fill")}
        >
          {cartList.length > 0 && (
            <div className="cartCornerMark">{cartList.length}</div>
          )}
        </div>
        {/* 购物车价格 */}
        <div className="main">
          <div className="price">
            <span className="payableAmount">
              {Number(totalPrice.toFixed(0)).toLocaleString()}
              <span className="payableAmountUnit">&nbsp;円</span>
            </span>
          </div>
        </div>
        {/* 结算 or 起送 */}
        {cartList.length > 0 ? (
          <div className="goToPreview" onClick={handleClick}>
            レジに進む
          </div>
        ) : (
          <div className="minFee">無料配送</div>
        )}
      </div>
      {/* 添加visible类名 div会显示出来 */}
      <div className={classNames("cartPanel", visible && "visible")}>
        <div className="cart-header">
          <span className="text">カート</span>
          <span className="clearCart" onClick={() => dispatch(clearCart())}>
            全ての商品を削除
          </span>
        </div>

        {/* 购物车列表 */}
        <div className="scrollArea">
          {cartList
            .filter((item) => item.count > 0)
            .map((item) => {
              return (
                <div className="cartItem" key={item.id}>
                  <img className="shopPic" src={item.picture} alt="" />
                  <div className="main">
                    <div className="skuInfo">
                      <div className="name">{item.name}</div>

                      <div className="flavors">
                        {item.flavors && (
                          <CartDropdown
                            flavors={matchingFoodsByName[item.name].flavors}
                            defaultFlavor={item.flavors}
                            onFlavorSelect={(newFlavor) =>
                              handleFlavorSelect(item.id, item.name, newFlavor)
                            }
                          ></CartDropdown>
                        )}
                      </div>
                    </div>
                    <div className="payableAmount">
                      <span className="price">
                        {item.price.toLocaleString()}
                      </span>
                      <span className="goods-price-unit">&nbsp;円</span>
                    </div>
                  </div>
                  <div className="skuBtnWrapper btnGroup">
                    {/* 数量组件 */}
                    <Count
                      count={item.count}
                      onPlus={() => dispatch(increCount({ id: item.id }))}
                      onMinus={() => dispatch(decreCount({ id: item.id }))}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Cart;
