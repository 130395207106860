import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import App from "./App";
// 注入store

import { Provider } from "react-redux";
import store from "./store";
import router from "./components/router";
import Comments from "./components/Comments/Comments";
import Checkout from "./components/CheckOut";
import { message } from "antd";
const root = createRoot(document.getElementById("root"));
// 配置错误消息的位置
message.config({
  // @ts-ignore
  top: "20%", // 设置消息显示在画面中央
  duration: 2, // 设置消息显示的持续时间（秒）
  maxCount: 1, // 设置同时显示的最大消息数
});
root.render(
  <Provider store={store}>
    {/* @ts-ignore */}
    <RouterProvider router={router}>
      <App />
      <Comments></Comments>
      <Checkout></Checkout>
    </RouterProvider>
  </Provider>
);
