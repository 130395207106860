import "./index.scss";
import { Card, Form, Input, Button, message } from "antd";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { fetchLogin } from "../../store/modules/user";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { loginAPI, getAvatarAPI } from "../../apis/user";

const Login = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const onFinish = async (values) => {
    console.log(values);

    try {
      // 调用 API
      const res = await loginAPI(values);
      const { token, userId, phoneNumber, shopId } = res.data;
      // 触发异步action fetchLogin
      dispatch(fetchLogin({ token, userId, phoneNumber, shopId }));
      // 将 userId 存储到 localStorage
      localStorage.setItem("userId", userId);
      if (shopId) {
        localStorage.setItem("shopId", shopId);
      }

      const response: any = await getAvatarAPI(userId);
      if (response.avatar) {
        localStorage.setItem("avatar", response.avatar);
      }

      // 1. 跳转到首页
      navigate("/");
      // 2. 提示一下用户
      message.success("ログインサクセス");
    } catch (error) {
      // 捕获到错误时输出到 message.error
      if (error === "Invalid mobile") {
        message.error("未登録の携帯電話番号です");
      } else if (error === "Invalid code") {
        message.error("無効な検証コードです");
      } else {
        message.error("エラーが発生しました。もう一度お試しください。");
      }
      console.error("Login error:", error);
    }
  };
  return (
    <div className="login">
      <Card className="login-container">
        <img className="login-logo" src={logo} alt="" />
        {/* 登录表单 */}
        <Form onFinish={onFinish} validateTrigger="onBlur">
          <Form.Item
            name="mobile"
            // 多条校验逻辑 先校验第一条 第一条通过之后再校验第二条
            rules={[
              {
                required: true,
                message: "電話番号を入力してください",
              },
              {
                pattern: /^1[3-9]\d{9}$/,
                message: "正しい電話番号の形式を入力してください",
              },
            ]}
          >
            <Input size="large" placeholder="電話番号を入力してください" />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: "認証コードを入力してください",
              },
            ]}
          >
            <Input size="large" placeholder="認証コードを入力してください" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" block>
              ログイン
            </Button>
          </Form.Item>
          <Form.Item>
            <Link to="/">
              <Button type="primary" size="large" block>
                前に戻る
              </Button>
            </Link>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
