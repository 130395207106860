import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, message, Card } from 'antd';
import './index.scss';

const OrderCompletion = () => {
  const navigate = useNavigate();
  const order = useSelector((state: any) => state.orders.currentOrder);
  console.log('order', order);

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <div className="completion">
      <Card className="order-completion">
        <h1>注文完了</h1>
        <p>注文番号: {order.oid}</p>
        <p>
          合計金額: {Number(order.amount).toFixed(0).toLocaleString()}
          <span className="payableAmountUnit">&nbsp;円</span>
        </p>
        <p>ご購入ありがとうございます！</p>
        <Button type="primary" onClick={handleBackToHome}>
          ホームに戻る
        </Button>
      </Card>
    </div>
  );
};

export default OrderCompletion;
