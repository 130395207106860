import "./index.scss";
import { Card, Form, Input, Button, message } from "antd";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { fetchLogin } from "../../store/modules/user";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getCodeAPI, getAvatarAPI, sendEmailAPI } from "../../apis/user";
import { useState } from "react";

const MessageLogin = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [countdown, setCountdown] = useState(0);
  const [email, setEmail] = useState('');
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const handleEmailChange = (e) => {
    let value = e.target.value;
    const input = e.target;
    const cursorPosition = input.selectionStart;
    console.log("value", value);
    if (!value.includes('@')) {
      value += '@';
      setEmail(value);
      form.setFieldsValue({ email: value });
  
      // 设置光标位置在 @ 符号之前
      setTimeout(() => {
        input.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
    } else {
      setEmail(value);
      form.setFieldsValue({ email: value });
    }
  };
  const handleSendCode = async () => {
    try {
      // 触发表单验证
      await form.validateFields(['email']);
      const email = form.getFieldValue('email');
      console.log('email', email);
      if (!email) {
        message.warning('メールアドレスを入力してください');
        return;
      }
      setLoading(true);
      console.log('email', email);
      const response: any = await sendEmailAPI(email);
      setIsButtonDisabled(true);
      setLoading(false);
      // 开启60秒计时器
      let timeLeft = 60;
      setCountdown(timeLeft);
      const newTimer = setInterval(() => {
        timeLeft -= 1;
        setCountdown(timeLeft);
        if (timeLeft <= 0) {
          clearInterval(newTimer);
          setIsButtonDisabled(false);
        }
      }, 1000);
      setTimer(newTimer);
      console.log('response', response);
      if (response.success) {
        message.success('認証コードを送信しました');
      } else {
        if (timer) {
          clearInterval(timer);
        }
        setTimer(null);
        setIsButtonDisabled(false);
        message.error('認証コードの送信に失敗しました');
      }
    } catch (error) {
      setLoading(false);
      if (timer) {
        clearInterval(timer);
      }
      setTimer(null);
      setIsButtonDisabled(false);
      message.error('認証コードの送信に失敗しました');
    }
  };

  const onFinish = async (values) => {
    console.log(values);
    try {
      // 调用 API
      const res: any = await getCodeAPI(values);
      console.log('res', res);
      if (res.message === "Verification code expired") {
        message.error("認証コードの有効期限が切れました");
        return;
      }
      else if (res.message === "Invalid code") {
        message.error("無効な検証コードです");
        return;
      }
      const { token, userId, phoneNumber, shopId } = res.data;
      // 触发异步action fetchLogin
      dispatch(fetchLogin({ token, userId, phoneNumber, shopId }));
      // 将 userId 存储到 localStorage
      localStorage.setItem("userId", userId);
      if (shopId) {
        localStorage.setItem("shopId", shopId);
      }

      const response: any = await getAvatarAPI(userId);
      if (response.avatar) {
        localStorage.setItem("avatar", response.avatar);
      }

      // 1. 跳转到首页
      navigate("/");
      // 2. 提示一下用户
      message.success("ログインサクセス");
    } catch (error) {
      message.error("エラーが発生しました。もう一度お試しください。");
      console.error("Login error:", error);
    }
  };
  return (
    <div className="login">
      <Card className="login-container">
        <img className="login-logo" src={logo} alt="" />
        {/* 登录表单 */}
        <Form form={form} onFinish={onFinish} validateTrigger="onBlur">
          <Form.Item
            name="email"
            // 多条校验逻辑 先校验第一条 第一条通过之后再校验第二条
            rules={[
              {
                required: true,
                message: "メールアドレスを入力してください",
              },
              {
                type: "email",
                message: "正しいメールアドレスの形式を入力してください",
              },
            ]}
          >
            <div className="authMessage-container">
              <Input size="large"
                placeholder="メールアドレスを入力してください"
                value={email}
                onChange={handleEmailChange} />
              <Button
                type="primary"
                htmlType="button"
                size="large"
                block
                onClick={handleSendCode}
                loading={loading}
                disabled={isButtonDisabled}
              >
                {isButtonDisabled ? `${countdown}` : '送信'}
              </Button>
            </div>
          </Form.Item>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: "認証コードを入力してください",
              },
            ]}
          >
            <Input size="large" placeholder="認証コードを入力してください" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" block>
              ログイン
            </Button>
          </Form.Item>
          <Form.Item>
            <Link to="/">
              <Button type="primary" size="large" block>
                前に戻る
              </Button>
            </Link>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default MessageLogin;
