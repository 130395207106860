import { request } from '../../src/utils/request';

export async function addFlavorsAPI(flavors) {
  try {
    const response = await request({
      url: '/api/insert-flavors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        flavors,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含成功消息
    if (data.message === 'Flavors added successfully') {
      return { insertIds: data.insertIds, success: true };
    } else {
      throw new Error(data.error || 'Failed to add flavors');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function updateFoodFlavorsAPI(food_id, flavor_ids) {
  try {
    const response = await request({
      url: '/api/update-flavors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        food_id,
        flavor_ids,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含成功消息
    if (data.message === '食品の風味が正常に更新されました') {
      return { updateResult: data.updateResult };
    } else {
      throw new Error(data.error || '食品の風味の更新に失敗しました');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function getFlavorsAPI() {
  try {
    const response = await request({
      url: '/api/get-flavors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response || [];
    console.log('data:', data);

    // 检查响应数据是否包含风味数据
    if (Array.isArray(data)) {
      return data;
    } else {
      throw new Error(data.error || 'Failed to fetch flavors');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function deleteFlavorAPI(food_id) {
  try {
    const response = await request({
      url: `/api/delete-flavor?food_id=${food_id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含成功消息
    if (data.status === 200) {
      return { message: data.message };
    } else {
      throw new Error(data.message || '風味の更新に失敗しました');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
