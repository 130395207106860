import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { loginAPI, getAvatarAPI } from '../../apis/user';
import { fetchLogin } from '../../store/modules/user';

const QRLogin = () => {
  const [loginAttempted, setLoginAttempted] = useState(false);

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  //https://checkoutreact.top/#/qrlogin?mobile=13211111111&code=246810
  useEffect(() => {
    const loginFromURL = async () => {
      try {
        // 从URL中获取参数
        const hash = window.location.hash;
        const queryString = hash.includes('?') ? hash.split('?')[1] : '';
        const params = new URLSearchParams(queryString);
        console.log('params:', params);
        const values = {
          mobile: params.get('mobile'),
          code: params.get('code'),
        };
        // const values = {
        //     mobile: '13211111111',
        //     code: '246810',
        //   };

        // 调用 API
        const res = await loginAPI(values);
        const { token, userId, phoneNumber } = res.data;

        // 触发异步action fetchLogin
        dispatch(fetchLogin({ token, userId, phoneNumber }));

        // 将 userId 存储到 localStorage
        localStorage.setItem('userId', userId);

        const response: any = await getAvatarAPI(userId);
        if (response.avatar) {
          localStorage.setItem('avatar', response.avatar);
        }

        // 1. 跳转到首页
        navigate('/');
        // 2. 提示一下用户
        message.success('ログインサクセス');
      } catch (error) {
        // 捕获到错误时输出到 message.error
        if (error.message === 'Invalid mobile') {
          message.error('未登録の携帯電話番号です');
        } else if (error.message === 'Invalid code') {
          message.error('無効な検証コードです');
        } else {
          message.error('エラーが発生しました。もう一度お試しください。');
        }
        console.error('Login error:', error);
      } finally {
        setLoginAttempted(true);
      }
    };

    loginFromURL();
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!loginAttempted) {
      message.info('自動ログインを試みています...');
    }
  }, [loginAttempted]);

  return null; // 这个组件不需要渲染任何内容
};
export default QRLogin;
