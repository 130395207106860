// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/2.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/1.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".goods-count {\n  font-size: 16px;\n  display: inline-flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #ffffff;\n}\n.goods-count .minus,\n.goods-count .plus {\n  width: 20px;\n  height: 20px;\n  background-size: 20px 20px;\n  position: relative;\n}\n.goods-count .minus {\n  margin-right: 12.5px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center center;\n  background-size: contain;\n}\n.goods-count .plus {\n  margin-left: 12.5px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-position: center center;\n  background-size: contain;\n}", "",{"version":3,"sources":["webpack://./src/components/Count/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,oBAAA;EACA,8BAAA;EACA,mBAAA;EACA,yBAAA;AACF;AACE;;EAEE,WAAA;EACA,YAAA;EACA,0BAAA;EACA,kBAAA;AACJ;AACE;EACE,oBAAA;EACA,mDAAA;EACA,4BAAA;EACA,kCAAA;EACA,wBAAA;AACJ;AACE;EACE,mBAAA;EACA,mDAAA;EACA,4BAAA;EACA,kCAAA;EACA,wBAAA;AACJ","sourcesContent":[".goods-count {\n  font-size: 16px;\n  display: inline-flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #ffffff;\n\n  .minus,\n  .plus {\n    width: 20px;\n    height: 20px;\n    background-size: 20px 20px;\n    position: relative;\n  }\n  .minus {\n    margin-right: 12.5px;\n    background: url('../../images/2.jpg');\n    background-repeat: no-repeat;\n    background-position: center center;\n    background-size: contain\n  }\n  .plus {\n    margin-left: 12.5px;\n    background: url('../../images/1.jpg');\n    background-repeat: no-repeat;\n    background-position: center center;\n    background-size: contain\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
