// 编写store

import { createSlice } from '@reduxjs/toolkit';
import { getFoodsAPI } from '../../apis/food';

const foodsStore = createSlice({
  name: 'foods',
  initialState: {
    // 商品列表
    foodsList: [],
    // 菜单激活下标值
    activeIndex: 0,
    // 购物车列表
    cartList: [],
  },
  reducers: {
    // 更改商品列表
    setFoodsList(state, action) {
      state.foodsList = action.payload;
    },
    // 更改activeIndex
    changeActiveIndex(state, action) {
      state.activeIndex = action.payload;
    },
    // 添加购物车
    addCart(state, action) {
      // 是否添加过？以action.payload.id去cartList中匹配 匹配到了 添加过
      const item = state.cartList.find(
        (item) =>
          item.id === action.payload.id &&
          item.flavors === action.payload.flavors
      );
      if (item) {
        item.count++;
      } else {
        state.cartList.push(action.payload);
      }
    },
    // count增
    increCount(state, action) {
      // 关键点：找到当前要修改谁的count id
      const item = state.cartList.find((item) => item.id === action.payload.id);
      item.count++;
    },
    // count减
    decreCount(state, action) {
      // 关键点：找到当前要修改谁的count id
      const item = state.cartList.find((item) => item.id === action.payload.id);
      if (item.count === 0) {
        return;
      }
      if (item.count === 1) {
        item.count--;
        state.cartList = state.cartList.filter(
          (cartItem) => cartItem.id !== action.payload.id
        );
      }
      item.count--;
    },
    // 清除购物车
    clearCart(state) {
      state.cartList = [];
    },
    // 更改购物车中flavor
    changeCartFlavor(state, action) {
      const item = state.cartList.find(
        (item) => item.id === action.payload.itemId
      );
      if (item) {
        item.flavors = action.payload.flavors;
      }
    },
    // 删除购物车中的单个商品
    removeCartItem(state, action) {
      state.cartList = state.cartList.filter(
        (item) => item.id !== action.payload.itemId
      );
    },
  },
});

// 异步获取部分
const {
  setFoodsList,
  changeActiveIndex,
  addCart,
  increCount,
  decreCount,
  clearCart,
  changeCartFlavor,
  removeCartItem,
} = foodsStore.actions;
const fetchFoodsList = () => {
  return async (dispatch) => {
    // 编写异步逻辑
    const res: any = await getFoodsAPI();
    // 过滤掉 foods 项中 status 为 stage 的对象
    const onlineFoods = res.map((category) => {
      return {
        ...category,
        foods: category.foods.filter((food) => food.status !== 'stage'),
      };
    });
    // 调用dispatch函数提交action
    dispatch(setFoodsList(onlineFoods));
  };
};

export {
  fetchFoodsList,
  changeActiveIndex,
  addCart,
  increCount,
  decreCount,
  clearCart,
  changeCartFlavor,
  removeCartItem,
};

const reducer = foodsStore.reducer;

export default reducer;
