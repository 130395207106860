import React from 'react';
import { useState } from 'react';
import { Tag, Modal } from 'antd';
import './index.scss';
const statusMap = {
  Pending: '調理中',
  Processing: '処理中',
  Delivered: '配達済み',
  Cancelled: 'キャンセル済み',
  Returned: '返品済み',
};


const statusColorMap = {
  Pending: 'orange',
  Processing: 'blue',
  Delivered: 'green',
  Cancelled: 'red',
  Returned: 'gray',
};

const OrderStatusManagerTag = ({ status, onStatusChange }) => {
  const [visible, setVisible] = useState(false);

  const handleTagClick = (newStatus) => {
    Modal.confirm({
      title: '確認',
      content: `ステータスを「${statusMap[newStatus]}」に変更しますか？`,
      okText: '確認',
      cancelText: 'キャンセル',
      onOk() {
        onStatusChange(newStatus);
        setVisible(false);
      },
      onCancel() {
        setVisible(false);
      },
    });
  };
  return (
    <div className='statusContainer'>
      <Tag color={statusColorMap[status] || 'blue'} onClick={() => setVisible(!visible)}>
        {statusMap[status] || status}
      </Tag>
      {visible && (
        <div className='select-status'>
          {Object.keys(statusMap)
            .filter((key) => key !== status)
            .map((key) => (
              <Tag
                key={key}
                color={statusColorMap[key]}
                onClick={() => handleTagClick(key)}
              >
                {statusMap[key]}
              </Tag>
            ))}
        </div>
      )}
    </div>
  );
};

export default OrderStatusManagerTag;