import { request } from '../utils/request';

export async function addOrderAPI(uid, amount, orderFoodItems) {
  try {
    const response = await request({
      url: '/api/insert-orders',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        uid,
        amount,
        orderFoodItems,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含成功消息
    if (data.message === 'Order and order items added successfully') {
      return { success: true, orderId: data.orderId };
    } else {
      throw new Error(data.error || 'Failed to add order');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function getNewOrderAPI(oid) {
  try {
    const response = await request({
      url: `/api/get-newOrder`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        oid: oid,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含订单信息
    if (data && data.uid && data.amount && data.orderFoodItems) {
      return {
        success: true,
        order: {
          uid: data.uid,
          amount: data.amount,
          orderFoodItems: data.orderFoodItems,
        },
      };
    } else {
      throw new Error(data.error || 'Failed to fetch order');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function getAllOrderAPI(uid) {
  try {
    const response = await request({
      url: `/api/get-allOrder`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        uid: uid,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含订单信息
    if (data && data.orders) {
      return {
        success: true,
        result: {
          uid: data.uid,
          shopId: data.shopId,
          orders: data.orders,
        },
      };
    } else {
      return {
        success: true,
        result: {
          uid: data.uid,
          shopId: data.shopId,
          orders: [],
        },
      };
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}


export async function getAllShopOrdersAPI(shop_id) {
  try {
    const response = await request({
      url: `/api/get-allShopOrders`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        shop_id: shop_id,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含订单信息
    if (data && data.orders) {
      return {
        success: true,
        result: {
          shopId: data.shopId,
          orders: data.orders,
        },
      };
    } else {
      return {
        success: true,
        result: {
          shopId: data.shopId,
          orders: [],
        },
      };
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function updateOrderStatusAPI(oid, status) {
  try {
    const response = await request({
      url: '/api/update-order-status',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        oid,
        status,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含成功消息
    if (data.message === 'Order status updated successfully') {
      return { success: true, oid: data.oid, status: data.status };
    } else {
      throw new Error(data.error || 'Failed to update order status');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
