import { createSlice } from '@reduxjs/toolkit';

const amazonProductStore = createSlice({
    name: 'amazonProduct',
    initialState: {
        // 商品リスト
        productList: [],
    },
    reducers: {
        // 商品リストを設定
        setProductList(state, action) {
            state.productList = action.payload;
        },
    },
});

const { setProductList } =
    amazonProductStore.actions;

export {
    setProductList,
};

const reducer = amazonProductStore.reducer;

export default reducer;