import React from 'react';
import { Tag } from 'antd';

const statusMap = {
    Pending: '調理中',
    Processing: '処理中',
    Delivered: '配達済み',
    Cancelled: 'キャンセル済み',
    Returned: '返品済み',
};


const statusColorMap = {
    Pending: 'orange',
    Processing: 'blue',
    Delivered: 'green',
    Cancelled: 'red',
    Returned: 'gray',
};

const OrderStatusTag = ({ status }) => {
    return (
        <Tag color={statusColorMap[status] || 'blue'}>
            {statusMap[status] || status}
        </Tag>
    );
};

export default OrderStatusTag;