import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { message,Spin } from 'antd';
import { updateOrderStatusAPI } from '../../apis/order';
import { checkPayPayPaymentDetailsAPI } from '../../apis/payment';
import './index.scss';
const PaymentStatusCheck = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  //テスト用URL
  //https://checkoutreact.top/#/qrlogin?mobile=13211111111&code=246810
  //http://localhost:3000/%E7%84%BC%E8%82%89%E5%B1%8B#/paymentStatusCheck?merchantPaymentId=2
  useEffect(() => {
    const loginFromURL = async () => {
      const hash = window.location.hash;
      const queryString = hash.includes('?') ? hash.split('?')[1] : '';
      const params = new URLSearchParams(queryString);
      const merchantPaymentId = params.get('merchantPaymentId')
      console.log('params:', merchantPaymentId);

      try {
        const result = await checkPayPayPaymentDetailsAPI(merchantPaymentId);
        console.log('result:', result);
        console.log('result.success:', result.data.status);

        // 条件を変更：statusが存在しないか、'COMPLETED'と等しくないかを確認
        if (!result.data.status || result.data.status !== 'COMPLETED') {
          throw new Error("注文の支払いに失敗しました");
        }

        // 支払い成功後、注文ステータスを更新
        const updateResult = await updateOrderStatusAPI(merchantPaymentId, "Pending");
        console.log('updateResult:', updateResult);
        if (updateResult.success) {
          navigate(`/orderCompletion?merchantPaymentId=${merchantPaymentId}`);
        } else {
          throw new Error("注文ステータスの更新に失敗しました");
        }
      } catch (error) {
        console.error('Error:', error);
        message.error(error.message || "支払いの確認中にエラーが発生しました");
        navigate("/");
      }
    };

    loginFromURL();
  }, [dispatch, navigate]);

  useEffect(() => {
    message.info('注文決済処理中...');
  }, []);

  return (
    <div className="loadingContainer">
      <Spin size="large" />
    </div>
  );
};

export default PaymentStatusCheck;