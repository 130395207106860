import './index.scss';
import { Card, Form, Input, Button, message, Radio } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import {
  checkUsernameAPI,
  uploadFileAPI,
  updateUserInfoAPI,
  updateFileAPI,
  getUserInfoAPI,
} from '../../apis/user';
import ImageUpload from '../ImageUpload';
import { setUserInfo } from '../../store/modules/user';

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state: any) => state.user.userInfo);
  const [form] = Form.useForm();
  const inputRef = useRef(null); // 获取 input 元素的引用
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState(
    localStorage.getItem('avatar') || null
  );
  const [userName, setUserName] = useState('');
  const [gender, setGender] = useState(null); // 添加状态
  const [isGenderSet, setIsGenderSet] = useState(false); // 添加标志

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response: any = await getUserInfoAPI(userInfo.userId);
        const { gender, uname } = response.user;
        if (response.status === 200) {
          dispatch(
            setUserInfo({
              ...userInfo,
              uname: uname,
              gender: gender,
            })
          );
          form.setFieldsValue({
            gender: gender,
            userName: uname,
          });
          setUserName(uname); // 更新状态
          setGender(gender); // 更新状态
          setIsGenderSet(gender !== null); // 设置标志
          // 直接操作 DOM 元素，设置 input 的值
          if (inputRef.current) {
            inputRef.current.focus();
          }
          console.log('Updated form values:', form.getFieldsValue());
        } else {
          message.error('ユーザー情報の取得に失敗しました');
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, [form]);

  const handleBack = () => {
    navigate(-1); // 返回到上一步
  };

  const onFinish = async (values) => {
    try {
      if (fileList.length > 0) {
        const file = fileList[0];
        console.log('Uploading file:', file);
        const uploadResult: any = await uploadFileAPI(file);
        console.log('Upload result:', uploadResult);
        const { url, filename, mimetype, size } = uploadResult;
        console.log('Upload result:', uploadResult);
        setImageUrl(url);
        // 调用 updateFileAPI 更新文件信息
        const response = await updateFileAPI(
          userInfo.userId,
          url,
          filename,
          mimetype,
          size
        );
        console.log('Update file response:', response);
        dispatch(
          setUserInfo({
            ...userInfo,
            avatar: url, // 只更新 avatar
          })
        );
        localStorage.setItem('avatar', url);
      } else {
        console.log('No file selected');
      }
      console.log('Received values:', values);
      // 在这里处理其他表单数据，例如提交到服务器
      // submitFormData(values);
    } catch (error) {
      message.error('アップロードが失敗しました');
      console.error('アップロードが失敗しました:', error);
    }
    try {
      let uname = values.userName;
      const response: any = await checkUsernameAPI(uname);
      setLoading(false);
      if (fileList.length === 0 && isGenderSet && response.haveBeenRegistered) {
        message.error('更新すべき情報がありません');
        return;
      }
      if (response.haveBeenRegistered) {
        uname = null;
      } else {
        console.log(uname);
      }
      // 调用 updateUserInfoAPI 更新用户信息
      const updateResult = await updateUserInfoAPI(
        userInfo.userId,
        uname,
        values.gender
      );
      console.log('Update user info result:', updateResult);
      if (updateResult.data.message === 'User info updated successfully') {
        message.success('ユーザー情報が更新しました');

        // 更新 Redux 中的 userInfo
        dispatch(
          setUserInfo({
            ...userInfo,
            uname: values.userName,
            gender: values.gender,
          })
        );
      } else {
        message.error('更新が失敗しました');
      }
    } catch (error) {
      message.error('更新が失敗しました');
    }
  };

  const handleCheckUsername = async () => {
    try {
      const uname = form.getFieldValue('userName');
      if (!uname) {
        message.warning('ユーザー名を入力してください');
        return;
      }
      setLoading(true);
      const response: any = await checkUsernameAPI(uname);
      setLoading(false);
      console.log('response', response);
      if (response.haveBeenRegistered) {
        message.error('このユーザー名は既に登録されています');
      } else {
        message.success('このユーザー名は使用可能です');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error checking username:', error);
      message.error('ユーザー名の確認中にエラーが発生しました');
    }
  };

  const handleFormChange = () => {
    const userNameValue = form.getFieldValue('userName');
    console.log('当前用户名值:', userNameValue);
    // 检查 userNameValue 是否为 undefined
    if (userNameValue === undefined) {
      return;
    }
    form.validateFields(['userName']).catch((errorInfo) => {
      if (errorInfo.errorFields && errorInfo.errorFields.length === 0) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    });
  };

  const handleImageChange = (newFileList) => {
    // 使用 JSON.stringify 进行深度比较
    if (JSON.stringify(newFileList) !== JSON.stringify(fileList)) {
      console.log('Selected files:', newFileList);
      setFileList(newFileList);
    }
  };

  return (
    <div className="user-settings">
      <Card className="user-settings-container">
        {/* 登录表单 */}
        <Form
          form={form}
          initialValues={{ gender: 'male' }}
          onFinish={onFinish}
          validateTrigger="onChange"
          onValuesChange={handleFormChange}
        >
          <Form.Item
            name="userName"
            // 多条校验逻辑 先校验第一条 第一条通过之后再校验第二条
            rules={[
              {
                required: true,
                message: 'ユーザー名を入力してください',
              },
              {
                pattern: /^[\u4e00-\u9fa5\u3040-\u30FFa-zA-Z0-9]{2,20}$/,
                message:
                  'ユーザー名は2〜20文字の文字、英字または数字でなければなりません。特殊文字は使用できません。',
              },
            ]}
          >
            <div className="userName-container">
              <Input
                size="large"
                placeholder="ユーザー名を入力してください"
                value={userName} // 绑定状态
                onChange={(e) => setUserName(e.target.value)} // 更新状态
                ref={inputRef}
              />
              <Button
                type="primary"
                htmlType="button"
                size="large"
                block
                onClick={handleCheckUsername}
                loading={loading}
                disabled={isButtonDisabled}
              >
                確認
              </Button>
            </div>
          </Form.Item>
          <Form.Item
            name="gender"
            rules={[
              {
                required: false,
                message: '性別を選択してください',
              },
            ]}
          >
            <div className="gender-container">
              <Radio.Group
                value={gender}
                onChange={(e) => setGender(e.target.value)} // 更新状态
              >
                {isGenderSet ? (
                  gender === 'male' ? (
                    <Radio value="male">男性</Radio>
                  ) : (
                    <Radio value="female">女性</Radio>
                  )
                ) : (
                  <>
                    <Radio value="male">男性</Radio>
                    <Radio value="female">女性</Radio>
                  </>
                )}
              </Radio.Group>
            </div>
          </Form.Item>
          <Form.Item
            name="avatar"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <div className="avatar-container">
              <ImageUpload
                imageUrl={imageUrl}
                fileList={fileList}
                onChange={handleImageChange}
                maxCount={1}
                allowedTypes={[
                  'image/jpeg',
                  'image/png',
                  'image/gif',
                  'image/bmp',
                  'image/webp',
                ]}
              ></ImageUpload>
            </div>
          </Form.Item>
          <Form.Item>
            <div className="button-container">
              <Button
                type="primary"
                onClick={handleBack}
                size="large"
                className="handleBack"
              >
                前に戻る
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="handleUpload"
              >
                ユーザー情報をアップデート
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ProfileSettings;
