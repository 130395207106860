import './index.scss';
import { useDispatch } from 'react-redux';
import loginImage from '../../assets/login.jpg';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Dropdown, Menu, Space } from 'antd';
import { clearUserInfo } from '../../store/modules/user';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { checkAddressRecordAPI } from '../../apis/user';
import { clearCart } from '../../store/modules/takeaway';

const User = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
  const [shopId, setShopId] = useState(localStorage.getItem('shopId') || null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState(
    localStorage.getItem('avatar') || '/uploads/user.jpg'
  );

  const handleAddressCheck = async () => {
    const addressRecord: any = await checkAddressRecordAPI(userId);
    if (addressRecord.hasAddress === false) {
      navigate('/address');
    } else {
      navigate('/updateAddress', { state: { previousPath: '/checkout' } });
    }
  };

  const handleOrderCheck = () => {
    navigate('/orderCheck');
  };


  const handleProfileSettings = () => {
    navigate('/profileSettings');
  };

  const handleLogout = () => {
    // 清空 localStorage 中的 token 和 userId
    localStorage.removeItem('token_key');
    localStorage.removeItem('userId');
    localStorage.removeItem('avatar');
    localStorage.removeItem('shopId');
    // 调用 clearUserInfo 清空用户信息 回到app组件
    dispatch(clearUserInfo());
    dispatch(clearCart());
    // 2. 提示一下用户
    message.success('ログアウト完了');
    // 延迟刷新浏览器
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleHistoryCheck = () => {
    navigate('/purchaseHistory');
  };

  // const menu = (
  //   <Menu>
  //     <Menu.Item key="profileSettings" onClick={handleProfileSettings}>
  //       個人情報設定
  //     </Menu.Item>
  //     <Menu.Item key="addressCheck" onClick={handleAddressCheck}>
  //       配達先確認
  //     </Menu.Item>
  //     <Menu.Item key="logout" onClick={handleLogout}>
  //       ログアウト
  //     </Menu.Item>
  //   </Menu>
  // );

  const items = [
    {
      label: <Space>個人情報設定</Space>,
      key: 'profileSettings',
      onClick: handleProfileSettings,
    },
    {
      label: <Space>配達先確認</Space>,
      key: 'addressCheck',
      onClick: handleAddressCheck,
    },
    ...(shopId ? [{
      label: <Space>注文管理</Space>,
      key: 'orderCheck',
      onClick: handleOrderCheck,
    }] : []),
    {
      label: <Space>購入履歴</Space>,
      key: 'purchaseHistory',
      onClick: handleHistoryCheck,
    },
    {
      label: <Space>ログアウト</Space>,
      key: 'logout',
      onClick: handleLogout,
    },
  ];

  return (
    <div className="user">
      <div className="user-icon">
        {shopId && (
          <div className="crown-icon">
          </div>
        )}
        {userId ? (
          <div className="logged-in">
            <Dropdown
              menu={{ items }}
              trigger={['click']}
              open={showDropdown}
              onOpenChange={setShowDropdown}
            >
              <img
                src={avatar}
                alt=""
                onClick={() => setShowDropdown(!showDropdown)}
              />
            </Dropdown>
          </div>
        ) : (
          <div className="not-logged-in">
            <Link to="/messageLogin">
              <img src={loginImage} alt="" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default User;
