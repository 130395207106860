import './index.scss';
import { Select, Tooltip } from 'antd';

const Dropdown = ({ defalutStatus, onStatusSelect, selectedStatus }) => {
  const { Option } = Select;
  const placeholderText = defalutStatus === 'online' ? 'オンライン' : 'ステージ';
  const handleStatusChange = (value) => {
    onStatusSelect(value);
  };
  return (
    <div className="dropdown">
      <Select
        style={{ width: 150 }}
        placeholder={placeholderText}
        value={selectedStatus}
        onSelect={handleStatusChange}
      >
        <Option value="online">
          <Tooltip title="オンライン">
            <span>オンライン</span>
          </Tooltip>
        </Option>
        <Option value="stage">
          <Tooltip title="ステージ">
            <span>ステージ</span>
          </Tooltip>
        </Option>
      </Select>
    </div>
  );
};

export default Dropdown;
