import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import './index.scss';
import User from '../user';
import { Dropdown, Menu } from 'antd';
import { changeActiveIndex } from '../../store/modules/takeaway';

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, userInfo } = useSelector((state: any) => state.user);
  const inputRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const { foodsList, activeIndex, cartList } = useSelector(
    (state: any) => state.foods
  );
  const [dropdownVisible, setDropdownVisible] = useState(true);

  const handleFocus = (event) => {
    const input = event.target;
    const defaultValue = '料理名を入力してください';
    if (input.value === defaultValue) {
      input.value = ''; // 清除默认的文字
      // 使用 setTimeout 确保在焦点事件完成后设置光标位置
      setTimeout(() => {
        input.setSelectionRange(0, 0);
      }, 0);
    } else {
      // 使用 setTimeout 确保在焦点事件完成后设置光标位置
      setTimeout(() => {
        input.setSelectionRange(input.value.length, input.value.length);
      }, 0);
    }
    setDropdownVisible(true); // 输入框获得焦点时显示 Dropdown
  };
  const handleVisibleChange = (flag) => {
    setDropdownVisible(flag);
  };

  const handleClick = () => {
    if (token && userInfo.userId) {
      navigate('/comments'); // 如果有 token 和 userId，跳转到结算页面
    } else {
      navigate('/messageLogin'); // 否则，跳转到登录页面
    }
  };
  const handleShopClick = () => {
    if (token && userInfo.userId) {
      navigate('/shop'); // 如果有 token 和 userId，跳转到结算页面
    } else {
      navigate('/messageLogin'); // 否则，跳转到登录页面
    }
  };
  const handleChange = (event) => {
    const value = event.target.value;
    const defaultValue = '料理名を入力してください';

    // 如果输入值是默认字符串，则不进行匹配
    if (value === defaultValue) {
      return;
    }

    // 允许用户清空输入框，并清空匹配结果
    if (value.trim() === '') {
      setSearchText('');
      setResults([]); // 清空匹配结果
      return;
    }
    setSearchText(value);

    console.log('输入值:', value);
    console.log('foodsList:', foodsList);

    // 递归检查对象的所有键值对
    const checkMatch = (obj, value) => {
      const chars = value.split(''); // 将输入值拆分为字符数组
      return Object.values(obj).some((val) => {
        if (typeof val === 'object' && val !== null) {
          return checkMatch(val, value);
        }
        val = val == null ? '' : val;
        return chars.some((char) => val.toString().includes(char));
      });
    };

    // 根据输入值匹配 foodsList 中每一个键的值
    const matchedTags = [];
    const matchedFoods = [];

    foodsList.forEach((item) => {
      if (checkMatch(item.name, value) && item.foods.length > 0) {
        matchedTags.push({ type: 'tag', name: item.name });
      }
      item.foods.forEach((food) => {
        if (checkMatch(food, value)) {
          matchedFoods.push({
            type: 'food',
            name: food.name,
            picture: food.picture
              ? food.picture
              : '/uploads/default/noPicture.jpg',
          });
        }
      });
    });
    const matchedResults = [...matchedTags, ...matchedFoods];
    setResults(matchedResults);
    console.log('匹配结果:', matchedResults);
  };

  const handleItemClick = async (result) => {
    console.log('Item clicked:', result);
    const previousSelected: any = document.querySelector(
      '.cate-goods[data-selected="true"]'
    );
    if (previousSelected) {
      previousSelected.style.outline = 'none';
      previousSelected.removeAttribute('data-selected');
    }
    if (result.type === 'tag') {
      const index = foodsList.findIndex((item) => item.name === result.name);
      if (index !== -1) {
        await dispatch(changeActiveIndex(index));
      }
    } else if (result.type === 'food') {
      const index = foodsList.findIndex((item) =>
        item.foods.some((food) => food.name === result.name)
      );
      const foodIndex = foodsList[index].foods.findIndex(
        (food) => food.name === result.name
      );
      console.log('foodIndex:', foodIndex);
      if (index !== -1) {
        await dispatch(changeActiveIndex(index));
        // 为包含指定 food 的 menu 添加轮廓线
        const menuItem: any = document.querySelector(
          `.cate-goods:nth-child(${foodIndex + 2})`
        );
        console.log('menuItem:', menuItem);
        if (menuItem) {
          menuItem.style.outline = '2px solid red';
          menuItem.style.outlineOffset = '-2px'; // 将轮廓线向内缩进2像素
          menuItem.style.borderRadius = '8px'; // 设置轮廓线的边缘弧度
          menuItem.setAttribute('data-selected', 'true'); // 标记当前选中的元素
        }
      }
    }
    // 在这里添加你想要的其他处理逻辑
  };
  const menu = (
    <Menu className="menu-search-dropdown">
      {results.map((result, index) => (
        <Menu.Item key={index} onClick={() => handleItemClick(result)}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {result.type === 'food' && (
              <img
                src={result.picture}
                alt={result.name}
                style={{ width: '20px', height: '20px', marginRight: '8px' }}
              />
            )}
            {result.name}
          </div>
        </Menu.Item> // 渲染 name 属性
      ))}
    </Menu>
  );

  return (
    <nav className="nav">
      <div className="menu">
        <div className="menu-item active">
          注文<span className="menu-active-bar"></span>
        </div>
        <div className="menu-item" onClick={handleClick}>
          コメント
        </div>
        <div className="menu-item" onClick={handleShopClick}>
          店舗
        </div>
      </div>
      <User></User>

      <div className="menu-search">
        <Dropdown
          overlay={menu}
          visible={results.length > 0 && dropdownVisible}
          onVisibleChange={handleVisibleChange}
        >
          <div className="menu-form">
            <div className="menu-search-icon"></div>
            <input
              type="search"
              className="menu-search-text"
              placeholder="料理名を入力してください"
              defaultValue="料理名を入力してください"
              ref={inputRef}
              onFocus={handleFocus}
              onChange={handleChange}
              value={searchText}
            />
          </div>
        </Dropdown>
      </div>
    </nav>
  );
};

export default NavBar;
