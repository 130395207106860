import { createSlice } from '@reduxjs/toolkit';
import { addOrderAPI } from '../../apis/order';

const orderStore = createSlice({
  name: 'orders',
  initialState: {
    // 订单列表
    orderList: [],
    // 当前订单
    currentOrder: {
      oid: null,
      uid: null,
      amountr: null,
      orderFoodItems: [],
    },
  },
  reducers: {
    // 设置订单列表
    setOrderList(state, action) {
      state.orderList = action.payload;
    },
    // 添加订单
    addOrder(state, action) {
      state.orderList.push(action.payload);
    },
    // 设置当前订单
    setCurrentOrder(state: any, action) {
      state.currentOrder = {
        oid: action.payload.oid || null,
        uid: action.payload.uid || null,
        amount: action.payload.amount || null,
        orderFoodItems: action.payload.orderFoodItems || [],
      };
    },
    // 清除当前订单
    clearCurrentOrder(state: any) {
      state.currentOrder = {
        uid: null,
        amount: null,
        orderFoodItems: [],
      };
    },
  },
});

const { setOrderList, addOrder, setCurrentOrder, clearCurrentOrder } =
  orderStore.actions;

//   const fetchOrderList = () => {
//     return async (dispatch) => {
//       // 编写异步逻辑
//       const res = await getOrderListAPI();
//       // 调用dispatch函数提交action
//       dispatch(setOrderList(res));
//     };
//   };

// const createOrder = (uid, amount, orderFoodItems) => {
//   return async (dispatch) => {
//     try {
//       const response = await addOrderAPI(uid, amount, orderFoodItems);
//       if (response.success) {
//         dispatch(addOrder(response.order));
//         dispatch(setCurrentOrder(response.order));
//         const messageText = `注文が完了しました。注文ID: ${response.orderId}`;
//         message.success(messageText);
//       }
//     } catch (error) {
//       console.error('Failed to add order:', error);
//       message.error('注文に失敗しました。もう一度お試しください。');
//     }
//   };
// };

export {
  // fetchOrderList,
  setCurrentOrder,
  clearCurrentOrder,
};

const reducer = orderStore.reducer;

export default reducer;
