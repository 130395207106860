import "./index.scss";
import { Select, Tooltip } from "antd";

const CartDropdown = ({ flavors, defaultFlavor, onFlavorSelect }) => {
  const { Option } = Select;
  const handleFlavorChange = (value) => {
    onFlavorSelect(value);
    console.log(value);
  };
  return (
    <div className="cartDropdown">
      <Select style={{ width: 80 }} placeholder="塩味" defaultValue={defaultFlavor} onSelect={handleFlavorChange}>
        {flavors.map((flavor, index) => (
          <Option key={index} value={flavor}>
            <Tooltip title={flavor}>
              <span>{flavor}</span>
            </Tooltip>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default CartDropdown;
