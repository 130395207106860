// 编写store

import { createSlice } from '@reduxjs/toolkit';
import { getFoodsAPI } from '../../apis/food';
import { updateShop } from './user';

const foodsStore = createSlice({
  name: 'foods',
  initialState: {
    // 商品列表
    foodsList: [],
    // 菜单激活下标值
    activeIndex: 0,
    // 购物车列表
    cartList: [],
  },
  reducers: {
    // 更改商品列表
    setFoodsList(state, action) {
      state.foodsList = action.payload;
    },
    // 更改activeIndex
    changeActiveIndex(state, action) {
      state.activeIndex = action.payload;
    },
    // 添加购物车
    addCart(state, action) {
      // 是否添加过？以action.payload.id去cartList中匹配 匹配到了 添加过
      const item = state.cartList.find(
        (item) =>
          item.id === action.payload.id &&
          item.flavors === action.payload.flavors
      );
      if (item) {
        item.count++;
      } else {
        state.cartList.push(action.payload);
      }
    },
    //  セットをカートに追加
    addComboToCart(state, action) {
      const {
        id,
        picture,
        name,
        unit,
        description,
        food_tag_list,
        month_saled,
        like_ratio_desc,
        price,
        tag,
        count,
        flavors,
        comboId,
        selectedItems } = action.payload;
      const comboItem = state.cartList.find((item) => item.id === id);
      if (comboItem) {
        comboItem.count++;
      } else {
        state.cartList.push({
          id,
          picture,
          name,
          unit,
          description,
          food_tag_list,
          month_saled,
          like_ratio_desc,
          price,
          tag,
          count,
          flavors,
          comboId,
          selectedItems,
        });
      }
    },
    // count增
    increCount(state, action) {
      // 关键点：找到当前要修改谁的count id
      const item = state.cartList.find((item) => item.id === action.payload.id);
      item.count++;
      if (item.comboId) {
        item.selectedItems.forEach(category => {
          category.foods.forEach(food => {
            food.count++;
          });
        });
      }
    },
    // count减
    decreCount(state, action) {
      // 关键点：找到当前要修改谁的count id
      const item = state.cartList.find((item) => item.id === action.payload.id);
      if (item.count === 0) {
        return;
      }
      if (item.count === 1) {
        item.count--;
        state.cartList = state.cartList.filter(
          (cartItem) => cartItem.id !== action.payload.id
        );
      }
      item.count--;
      if (item.comboId) {
        item.selectedItems.forEach(category => {
          category.foods.forEach(food => {
            food.count--;
          });
        });
      }
    },
    // 清除购物车
    clearCart(state) {
      state.cartList = [];
    },
    // 更改购物车中flavor
    changeCartFlavor(state, action) {
      const item = state.cartList.find(
        (item) => item.id === action.payload.itemId
      );
      if (item) {
        item.flavors = action.payload.flavors;
      }
    },
    // 删除购物车中的单个商品
    removeCartItem(state, action) {
      state.cartList = state.cartList.filter(
        (item) => item.id !== action.payload.itemId
      );
    },

    changeCartComboItemFlavor(state, action) {
      const item = state.cartList.find(
        (item) => item.id === action.payload.originId
      );
      if (item) {
        item.selectedItems.forEach(category => {
          category.foods.forEach(food => {
            if (food.comboCategoryFoodId === action.payload.comboCategoryFoodId) {
              food.flavors = action.payload.flavors;
            }
          });
        });
      }
    },
  },
});

// 异步获取部分
const {
  setFoodsList,
  changeActiveIndex,
  addCart,
  addComboToCart,
  increCount,
  decreCount,
  clearCart,
  changeCartFlavor,
  removeCartItem,
  changeCartComboItemFlavor,
} = foodsStore.actions;
const fetchFoodsList = () => {
  return async (dispatch) => {
    // localStorage から shopName　を取得
    const shopName = localStorage.getItem('shopName');

    // 非同期ロジックを記述し、shopName をパラメータとして渡す
    const res: any = await getFoodsAPI(shopName || undefined);
    const shopId = res[0].shopId;

    // foods 項目の中で status が stage のオブジェクトをフィルタリング
    const onlineFoods = res
      .map((category) => {
        return {
          ...category,
          foods: category.foods.filter((food) => food.status !== 'stage'),
        };
      })
      .filter((category) => category.foods.length > 0); // foods が空の category をフィルタリング
    // dispatch 関数を呼び出して action を送信
    dispatch(setFoodsList(onlineFoods));
    dispatch(updateShop({ shopId, shopName }));
  };
};

export {
  fetchFoodsList,
  changeActiveIndex,
  addCart,
  addComboToCart,
  increCount,
  decreCount,
  clearCart,
  changeCartFlavor,
  removeCartItem,
  changeCartComboItemFlavor,
};

const reducer = foodsStore.reducer;

export default reducer;
