import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from '@react-spring/web';
import './index.scss';

interface ScrollWithThumbProps {
    children: React.ReactNode;
    thumbImageUrl: string;
    onFlagChange?: (flag: boolean) => void;
}

const ScrollWithThumb = ({ children, thumbImageUrl, onFlagChange }: ScrollWithThumbProps) => {
    const [imgTop, setImgTop] = useState(120);
    // 触角2の回転アニメーションを追加
    const tentacleAnimation = useSpring({
        from: { transform: 'rotate(0deg)' },
        to: async (next) => {
            while (true) {  // 無限ループ
                await next({ transform: 'rotate(-45deg)' });
                await next({ transform: 'rotate(0deg)' });
            }
        },
        config: { duration: 2000 },
    });

    const tentacle3Animation = useSpring({
        from: { transform: 'rotate(0deg)' },
        to: async (next) => {
            while (true) {
                await next({ transform: 'rotate(-60deg)' });
                await next({ transform: 'rotate(0deg)' });
            }
        },
        config: { duration: 2000 },
    });

    // 初期レンダリング時にすべての画像リソースをプリロード
    useEffect(() => {
        const assets = [
            '/uploads/default/tentacle1.svg',
            '/uploads/default/tentacle2.svg',
            '/uploads/default/tentacle3.svg',
            '/uploads/default/light.png'
        ];
        assets.forEach(src => {
            const img = new Image();
            img.src = src;
        });
    }, []);

    useEffect(() => {
        // .categoryエレメントをスクロールコンテナとして直接取得
        const container = document.querySelector('.category') as HTMLElement;
        if (!container) return;
        const updateThumbPosition = () => {
            const { scrollHeight, offsetHeight, scrollTop } = container;
            // ネイティブスクロールバーのサムの高さを計算
            const nativeThumbHeight = (offsetHeight * offsetHeight) / scrollHeight;
            // スクロール可能な領域の高さを計算
            const scrollableHeight = scrollHeight - offsetHeight;
            // サムの移動可能範囲を計算
            const availableTrackHeight = offsetHeight - nativeThumbHeight;
            // 現在のスクロール比率を計算
            const scrollRatio = scrollTop / scrollableHeight;
            // devicePixelRatioが1未満の場合、より小さい分母（例：0.5を乗算）を使用して調整比率を拡大。それ以外は変更なし
            const dr = window.devicePixelRatio;
            const factor1 = dr < 1 ? dr * 0.95 : 1;
            const factor2 = dr < 1 ? dr * 0.97 : 1;
            // スクロール比率を逆調整：devicePixelRatioが大きいほどadjustedRatioは小さくなる
            const adjustedRatio = scrollRatio / factor1;
            // 画面幅が700px未満の場合、availableTrackHeightに基づくアルゴリズムを使用
            // それ以外の場合、終点を1168pxに固定：imageY = 120 + scrollRatio *（目標値 - 120）
            const imageY = window.innerWidth < 700
                ? 120 + adjustedRatio * (600 - 120)
                : 120 + adjustedRatio * (840 - 120);
            setImgTop(imageY);
            // 画像座標が最終値に近づいた時、つまり底部に達した時にフラグをtrueとする

            const expectedFinal = window.innerWidth < 700 ? 595 / factor2 : 820 / factor2;
            const newFlag = imageY >= expectedFinal;
            onFlagChange(newFlag);
        };
        updateThumbPosition();

        container.addEventListener('scroll', updateThumbPosition);
        window.addEventListener('resize', updateThumbPosition);

        return () => {
            container.removeEventListener('scroll', updateThumbPosition);
            window.removeEventListener('resize', updateThumbPosition);
        };
    }, []);

    return (
        <div className="scroll-container">
            {children}
            {/* topの値はimgTopによって動的に更新され、その他のスタイルはscssに記述 */}

            <div className="scrollbar-thumb" style={{ top: `${imgTop}px` }}>
                {thumbImageUrl === "/uploads/default/lightSubmarine.png" && (
                    <div className="tentacles-group">
                        <svg
                            viewBox="0 0 1024 1024"
                            className="tentacles-svg"
                        >
                            {/* tentacle1を基本レイヤーとして使用 */}
                            <image
                                href="/uploads/default/tentacle1.svg"
                                width="1024"
                                height="1024"
                                className="tentacle1"
                            />
                            <animated.g
                                className="tentacle-pair"
                                style={tentacleAnimation}
                                transform-origin="508 341"
                            >
                                <image
                                    href="/uploads/default/tentacle2.svg"
                                    width="1024"
                                    height="1024"
                                    className="tentacle2"
                                />
                                <animated.image
                                    href="/uploads/default/tentacle3.svg"
                                    width="1024"
                                    height="1024"
                                    className="tentacle3"
                                    style={tentacle3Animation}
                                    transform-origin="530 446"
                                />
                            </animated.g>
                        </svg>
                    </div>)}
                {thumbImageUrl === "/uploads/default/lightSubmarine.png" && (
                    <div className="lightPicture">
                        <img src="/uploads/default/light.png" alt="light" className="load-more-img" />
                    </div>
                )}
                <img src={thumbImageUrl} alt="thumb" className='thumbImage' />
            </div>
        </div>
    );
};

export default ScrollWithThumb;