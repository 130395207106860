import foodsReducer from './modules/takeaway'
import { configureStore } from '@reduxjs/toolkit'
import userReducer from './modules/user'
import orderReducer from './modules/order'
import amazonProductReducer from './modules/amazonProduct'

const store = configureStore({
  reducer: {
    foods: foodsReducer,
    user: userReducer,
    orders: orderReducer,
    amazonProduct: amazonProductReducer,
  },
})

export default store