import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import User from "../user";
import "./index.scss";
import { Link } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import { setAddress } from "../../store/modules/user";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { addAddressAPI } from "../../apis/user";

const Address = () => {
  const [form] = Form.useForm();
  const [postalCode, setPostalCode] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const handleBack = () => {
    navigate(-1); // 返回到上一步
  };
  const dispatch = useDispatch();
  const { address, userInfo } = useSelector((state: any) => state.user);
  const handleSubmit = async (values) => {
    try {
      // 手动处理 postalCodeInput 的值
      values.postalCode = values.postalCodeInput;
      console.log("userInfo:", userInfo.userId); // 添加日志
      const keyId = userInfo.userId;
      // 添加 userId 到 values
      values.userId = keyId;

      // 创建一个新对象，复制 values 的所有属性，除了 userId
      const { userId, ...newValues } = values;

      delete newValues.userId;

      // 处理表单提交逻辑
      console.log("Form Data Submitted: ", values);

      // 发送 API 请求保存地址
      const response: any = await addAddressAPI(
        values.userId,
        values.postalCodeInput,
        values.prefecture,
        values.city,
        values.addressLine,
        values.phoneNumber
      );

      console.log("API Response: ", response);
      // 检查响应状态码和数据
      if (
        response.data.message !== "Address added successfully" ||
        !response.data.addressId
      ) {
        throw new Error("Failed to save address");
      }
      console.log("response", response.data.addressId);
      console.log("newValues", newValues);

      // 调用 dispatch 的 setAddress 方法
      dispatch(
        setAddress({ addressId: response.data.addressId, ...newValues })
      );
      console.log(address)

      // 获取上一步的路径
      const previousPath = location.state?.previousPath || "/";
      console.log("previousPath", previousPath);

      // 检查上一步是否是 checkout
      if (previousPath === "/checkout") {
        // 1. 跳转到支付页面
        navigate("/Payment");
        // 2. 提示一下用户
        message.success("住所が保存されました");
      } else {
        // 提示一下用户
        message.success("住所が保存されました");
      }
    } catch (error) {
      console.error("Error saving address: ", error);
      message.error("住所の保存に失敗しました");
    }
  };

  const handlePostalCodeChange = (e) => {
    setPostalCode(e.target.value);
  };

  const handleAutoFillAddress = async () => {
    try {
      const response = await axios.get(
        `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`
      );
      const data = response.data;
      if (data.results && data.results.length > 0) {
        const address = data.results[0];
        const { address1, address2, address3 } = address;
        console.log("data", address);
        form.setFieldsValue({
          prefecture: address1,
          city: `${address2} ${address3}`,
        });
      } else {
        console.error("No results found");
        message.error("無効な郵便番号が入力されました");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      message.error("未知のエラーが発生しました");
    }
  };

  return (
    <div className="addressConfirmationContainer">
      <div className="scrollArea">
        <div className="header">
          <span className="text">配達先住所の追加</span>
          <User></User>
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="addressForm"
        >
          <Form.Item label="郵便番号" name="postalCode" required>
            <Input.Group compact>
              <Form.Item
                name="postalCodeInput"
                noStyle
                rules={[
                  { required: true, message: "郵便番号を入力してください" },
                  {
                    pattern: /^\d{7}$/,
                    message: "7桁の郵便番号を入力してください",
                  },
                ]}
              >
                <Input
                  style={{ width: "calc(100% - 100px)" }}
                  onChange={handlePostalCodeChange}
                />
              </Form.Item>
              <Button
                type="primary"
                htmlType="button"
                style={{ width: "100px" }}
                onClick={handleAutoFillAddress}
              >
                住所を自動入力
              </Button>
            </Input.Group>
          </Form.Item>
          <Form.Item
            label="都道府県"
            name="prefecture"
            rules={[{ required: true, message: "都道府県を入力してください" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="市区町村"
            name="city"
            rules={[{ required: true, message: "市区町村を入力してください" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="住所"
            name="addressLine"
            rules={[{ required: true, message: "住所を入力してください" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="電話番号"
            name="phoneNumber"
            rules={[
              { required: true, message: "電話番号を入力してください" },
              {
                pattern: /^\d{10,11}$/,
                message: "有効な電話番号を入力してください",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              住所を保存します
            </Button>
          </Form.Item>
        </Form>
        {/* 结算 or 起送 */}
        <Button type="primary" onClick={handleBack} className="backToHome">
          前に戻る
        </Button>
      </div>
    </div>
  );
};

export default Address;
