import classNames from 'classnames';
import { useSelector } from 'react-redux';
import User from '../user';
import './index.scss';
import { Link, useNavigate } from 'react-router-dom';
import { checkAddressRecordAPI } from '../../apis/user';

const Checkout = () => {
  const { cartList } = useSelector((state: any) => state.foods);
  const { address, userInfo } = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  // 计算总价
  const totalPrice = cartList.reduce((a, c) => a + c.price * c.count, 0);

  // const isAllPropertiesEmpty = (obj) => {
  //   return Object.values(obj).every(value => value === '');
  // };

  const handleProceedToPayment = async () => {
    console.log('userInfo:', userInfo.userId); // 添加日志
    const addressRecord: any = await checkAddressRecordAPI(userInfo.userId);
    if (addressRecord.hasAddress === true) {
      navigate('/payment');
    } else {
      navigate('/address', { state: { previousPath: '/checkout' } });
    }
  };

  return (
    <div className="checkoutContainer">
      <div className="scrollArea">
        <div className="header">
          <span className="text">注文確認</span>
          <User></User>
        </div>
        <div className="cart-container">
          {cartList.map((item) => {
            if (item.count < 1) return null;
            const subtotal = item.count * item.price; // 计算小结价格
            return (
              <div className="cartItem" key={item.id}>
                <img className="shopPic" src={item.picture} alt="" />
                <div className="main">
                  <div className="skuInfo">
                    <div className="name">{item.name}</div>
                    <div className="flavors">
                      <span>{item.flavors}</span>
                    </div>
                  </div>
                  <div className="payableAmount">
                    <span className="price">
                      {Number(item.price.toFixed(0)).toLocaleString()}{' '}
                      <span className="payableAmountUnit">&nbsp;円</span>
                    </span>
                  </div>
                  <div className="subtotal">
                    <span className="subtotalText">小計：</span>
                    <span className="subtotalPrice">
                      {Number(subtotal.toFixed(0)).toLocaleString()}{' '}
                      <span className="subtotalUnit">&nbsp;円</span>
                    </span>
                  </div>
                </div>
                <div className="skuBtnWrapper btnGroup">
                  {/* 数量组件 */}
                  <div className="goods-count">
                    <span className="countText">注文数量：</span>
                    <span className="count">{item.count}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="cart">
        {/* fill 添加fill类名购物车高亮*/}
        {/* 购物车数量 */}
        <div className={classNames('icon', cartList.length > 0 && 'fill')}>
          {cartList.length > 0 && (
            <div className="cartCornerMark">{cartList.length}</div>
          )}
        </div>
        {/* 购物车价格 */}
        <div className="main">
          <div className="price">
            <span className="payableAmount">
              {Number(totalPrice.toFixed(0)).toLocaleString()}
              <span className="payableAmountUnit">&nbsp;円</span>
            </span>
          </div>
        </div>
        {/* 结算 or 起送 */}
        <Link to="/" className="link-block">
          <div className="goBack">
            <span>前に戻る</span>
          </div>
        </Link>

        <div className="goToPreview" onClick={handleProceedToPayment}>
          支払いに進む
        </div>
      </div>
    </div>
  );
};

export default Checkout;
