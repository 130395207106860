import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { setCurrentOrder } from '../../store/modules/order';
import { getNewOrderAPI } from '../../apis/order';
import './index.scss';

const OrderCompletion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const order = useSelector((state: any) => state.orders.currentOrder);
  const [loading, setLoading] = useState(true);
  const handleBackToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    const fetchOrderFromURL = async () => {
      const hash = window.location.hash;
      const queryString = hash.includes('?') ? hash.split('?')[1] : '';
      const queryParams = new URLSearchParams(queryString);
      const merchantPaymentId = queryParams.get('merchantPaymentId');
      console.log('params:', merchantPaymentId);

      if (merchantPaymentId) {
        const newOrder = await getNewOrderAPI(merchantPaymentId);
        console.log('newOrder:', newOrder);
        dispatch(
          setCurrentOrder({
            oid: merchantPaymentId,
            uid: newOrder.order.uid,
            amount: newOrder.order.amount,
            orderFoodItems: newOrder.order.orderFoodItems,
          })
        );
      }
    };

    fetchOrderFromURL();
    setLoading(false);
  }, [dispatch]);

  if (loading) {
    return null;
  }

  return (
    <div className="completion">
      <Card className="order-completion">
        <h1>注文完了</h1>
        <p>注文番号: {order.oid}</p>
        <p>
          合計金額: {Number(order.amount).toFixed(0).toLocaleString()}
          <span className="payableAmountUnit">&nbsp;円</span>
        </p>
        <p>ご購入ありがとうございます！</p>
        <Button type="primary" onClick={handleBackToHome}>
          ホームに戻る
        </Button>
      </Card>
    </div>
  );
};

export default OrderCompletion;
