import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withPageReload = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const isPageReloaded = sessionStorage.getItem('isPageReloaded');

      if (isPageReloaded) {
        // 页面被刷新
        navigate('/');
      } else {
        // 设置标志，表示页面已加载
        sessionStorage.setItem('isPageReloaded', 'true');
      }

      // 清理函数，在组件卸载时移除标志
      return () => {
        sessionStorage.removeItem('isPageReloaded');
      };
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };
};

export default withPageReload;